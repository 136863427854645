import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Tabs, TabsProps, Tag } from "antd";

import { RouteLinks } from "services/router.service";
import backIcon from "data/icons/arrow-left.svg";
import grayDotIcon from "data/icons/dot-gray.svg";
import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import { IEquipmentSource } from "store/listEquipment/listEquipment.types";
import { IContract } from "store/contracts/contracts.types";
import { ICase } from "store/cases/cases.types";
import hydraAccount from "data/icons/hydra-account.svg";
import { StatusItem } from "components/common";
import { CaseItemMobile, CreateCaseModal } from "components/cases";
import { ContractItemMobile } from "components/contracts";
import api from "services/api.service";

import "./CurrentEquipmentPageMobile.scss";

const tabs: TabsProps[ "items" ] = [
	{
		key: "cases",
		label: "Cases",
	},
	{
		key: "contracts",
		label: "Contracts",
	},
];

const CurrentEquipmentPageMobile = () => {
	const navigate = useNavigate();
	const { equipmentId } = useParams();

	const [ equipment, setEquipment ] = useState<IEquipmentSource>();
	const [ activeTab, setActiveTab ] = useState<"cases" | "contracts">("cases");

	const [ cases, setCases ] = useState<ICase[]>([]);
	const [ contracts, setContracts ] = useState<IContract[]>([]);

	const getCurrentEquipment = async (equipmentId: string) => {
		try {
			const response = await api.getEquipment(equipmentId);

			setEquipment(response.data);
		} catch (e) {
			message.error("Something went wrong!");
		}
	};

	const getEquipmentCases = async () => {
		if (equipmentId) {
			try {
				const response = await api.getEquipmentCases(equipmentId);

				setCases(response.data.results);
				setContracts([]);
			} catch (error) {
				message.error("Something went wrong!");
			}
		}
	};

	const getEquipmentContracts = async () => {
		if (equipmentId) {
			try {
				const response = await api.getEquipmentContracts(equipmentId);

				setContracts(response.data.results);
				setCases([]);
			} catch (error) {
				message.error("Something went wrong!");
			}
		}
	};

	const onTabChange = async (value: string) => {
		setActiveTab(value as "cases" | "contracts");

		if (value === "cases") {
			await getEquipmentCases();
		} else {
			await getEquipmentContracts();
		}
	};

	useEffect(() => {
		equipmentId && getCurrentEquipment(equipmentId);
		getEquipmentCases();
	}, []);

	if (!equipment) {
		return null;
	}

	return (
		<div className="default-page-wrapper current-case-page-wrapper">
			<Button
				className="back-button"
				type="link"
				onClick={ () => navigate(RouteLinks.PROTECTED.LIST_EQUIPMENT) }
			>
				<img src={ backIcon } alt="" />
				Back
			</Button>

			<div className="equipment-item">
				<div className="equipment-item-title">
					<StatusItem
						is_active={ equipment.is_active }
						is_pending={ equipment.is_pending }
					/>

          <span className="equipment-item-title-text">
            {equipment.serial_number}
          </span>

					<img
						className="equipment-item-title-icon"
						src={ grayDotIcon }
						alt=""
					/>

					<span className="equipment-item-title-date">
            {equipment.product.product_number}
					</span>

					{equipment.sla_ci && (
						<div className="equipment-item-title-tooltip">
							<Tag
								style={ {
									background: equipment.sla_ci.marker_bg_color,
									color: equipment.sla_ci.marker_text_color,
									margin: 0,
								} }
							>
								{equipment.sla_ci.marker}
							</Tag>
						</div>
					)}
				</div>

				<div className="equipment-item-content">
					{equipment.product.description}
				</div>

				<div className="equipment-item-info">
					<img
						alt=""
						className="equipment-item-info-icon"
						src={ equipment.contract.contractor.has_account ? hydraAccountBlue : hydraAccount }
					/>

					<span>
						{equipment.contract.contractor.official_name}
					</span>
				</div>
			</div>

			<div className="equipment-item-button">
				<CreateCaseModal
					block
					serial_number={ equipment.serial_number }
				/>
			</div>

			<Tabs
				items={ tabs }
				activeKey={ activeTab }
				onChange={ onTabChange }
			/>

			{activeTab === "cases" && cases.map((item: ICase) => (
				<CaseItemMobile
					item={ item }
					key={ item.id }
				/>
			))}

			{activeTab === "contracts" && contracts.map((item: IContract) => (
				<ContractItemMobile
					item={ item }
					key={ item.id }
				/>
			))}
		</div>
	);
};

export default CurrentEquipmentPageMobile;
