import axios, { AxiosHeaders, AxiosResponse } from "axios"

import {
  IInvitationComplete,
  INotification,
  IOrganization,
  IPassword,
  IUser,
  IUserWithToken,
} from "store/user/user.types";
import { ICase, ICaseActionsLog, ICaseEquipment, ICasesFilter, IComment } from "store/cases/cases.types";
import { IPagination, ISorting } from "./interface.service";
import { IDownload, IDownloadsFilter } from "store/downloads/downloads.types";
import { IEquipmentSource, IListEquipmentFilter } from "store/listEquipment/listEquipment.types";
import {
  IContract,
  IContractEquipment,
  IContractEquipmentsFilter,
  IContractsFilter,
  ICustomerSource,
  IOrganizationRegister,
  IShortContractorSource,
  ISlaCi,
} from "store/contracts/contracts.types";
import { IClient, IUserManagementFilter } from "store/userManagement/userManagement.types";
import { formatDateForApi } from "./data.service";
import { IStorageFilter, IStorageItem } from "store/storage/storage.types";
import { IProduct, IProductsFilter } from "store/products/products.types";
import { IContractor, IContractorsFilter } from "store/contractors/contractors.types";
import { IInvitationSource, IListInvitationFilter } from "store/invitations/invitations.types";
import { comments } from "./mock.data.service";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

const ApiService = {
  getConfig: function () {
    return {
      headers: {
        "Authorization": localStorage.getItem("AUTH_TOKEN") ? "JWT " + localStorage.getItem("AUTH_TOKEN") : undefined,
      },
    }
  },
  authorizeUser: async function (email: string, password: string): Promise<{ data: IUserWithToken }> {
    return await api.post("/user/token/", { email, password })
  },
  resetPassword: async function (email: string): Promise<void> {
    return await api.post("/user/reset-password/", { email })
  },
  changePassword: async function (password: string, token: string): Promise<void> {
    return await api.post("/user/reset-password/confirm/", { password, token });
  },
  registerUser: async function (
    first_name: string, last_name: string, phone_number: string, password: string, token: string,
  ): Promise<void> {
    return await api.post("/invitation/complete/", { first_name, last_name, phone_number, password, token });
  },
  getUserData: async function (): Promise<{ data: IUser }> {
    return api.get("/user/info/", this.getConfig());
  },
  setUserCookies: async function (action: "accept" | "reject" | "reset"): Promise<{ data: any }> {
    return api.post("/user/cookies/", { action }, this.getConfig());
  },
  setUserData: async function (user: IUser): Promise<{ data: IUser }> {
    return api.patch("/user/info/", user, this.getConfig());
  },
  setUserPassword: async function (user: IPassword): Promise<{ data: any }> {
    return api.patch("/user/change-password/", user, this.getConfig());
  },
  getCompanyData: async function (): Promise<{ data: IOrganization }> {
    return api.get("/organization/account/", this.getConfig());
  },
  setCompanyData: async function (user: Partial<IOrganization>): Promise<{ data: IOrganization }> {
    return api.patch("/organization/account/", user, this.getConfig());
  },
  contact: async function (email: string, text: string): Promise<{ data: any }> {
    return await api.post("/contact/", {
      email,
      text,
    }, this.getConfig());
  },
  contactUs: async function (email: string, text: string): Promise<{ data: any }> {
    return await api.post("/contact-us/", {
      email,
      text,
    }, this.getConfig());
  },
  getMeta: async function (): Promise<{ data: any }> {
    return api.get("/meta/", this.getConfig());
  },
  getOrganizationData: async function (): Promise<{ data: IOrganization }> {
    return api.get("/organization/info/", this.getConfig());
  },
  getCases: async function (filter: ICasesFilter, pagination: IPagination, sort: ISorting):
    Promise<{ data: { results: ICase[], count: number } }> {
    return await api.get("/cases/" +
      `?limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }` +
      `&customer=${ filter.client === "all" ? "" : filter.client }` +
      `&search=${ filter.search }` +
      `&status=${ filter.type === "all" ? "" : filter.type }` +
      `&created_at=${ formatDateForApi(filter.date_from) }` +
      `&closed_at=${ formatDateForApi(filter.date_to) }` +
      `&severity=${ filter.severity === null ? "" : filter.severity }`,
      this.getConfig(),
    )
  },
  exportCases: async function (filter: ICasesFilter, pagination: IPagination, sort: ISorting):
    Promise<AxiosResponse> {
    return await api.get("/cases/" +
      `?limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }` +
      `&customer=${ filter.client === "all" ? "" : filter.client }` +
      `&search=${ filter.search }` +
      `&status=${ filter.type === "all" ? "" : filter.type }` +
      `&created_at=${ formatDateForApi(filter.date_from) }` +
      `&closed_at=${ formatDateForApi(filter.date_to) }` +
      `&severity=${ filter.severity === null ? "" : filter.severity }` +
      `&export=xlsx`, { ...this.getConfig(), responseType: "blob" });
  },
  getDownloads: async function (
    filter: IDownloadsFilter,
    pagination: IPagination,
  ): Promise<{ data: { results: IDownload[], count: number } }> {
    return await api.get(`/shared-sources/` +
      `?limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }` +
      `&search=${ filter.search }`,
      this.getConfig(),
    )
  },
  createDownload: async function (download: IDownload): Promise<{ data: { id: number } }> {
    return await api.post("/shared-source/", {
      title: download.title,
      description: download.description,
      link: download.link,
      customers: download.customers,
      is_shared_to_all: download.is_shared_to_all,
    }, this.getConfig());
  },
  updateDownload: async function (download: IDownload): Promise<void> {
    return await api.put(`/shared-source/${ download.id }/`, {
      title: download.title,
      description: download.description,
      link: download.link,
      customers: download.customers,
      is_shared_to_all: download.is_shared_to_all,
    }, this.getConfig());
  },
  removeDownload: async function (id: number): Promise<void> {
    return await api.delete(`/shared-source/${ id }/`, this.getConfig());
  },
  getDownload: async function (id: string): Promise<{ data: IDownload }> {
    return await api.get(`/shared-source/${ id }/`, this.getConfig());
  },
  getEquipments: async function (filter: IListEquipmentFilter, sort: ISorting, limit: number, offset: number):
    Promise<{ data: { results: IEquipmentSource[], count: number } }> {
    return await api.get(
      `/equipment/` +
      `?limit=${ limit }` +
      `&offset=${ offset }` +
      `&search=${ filter.search }` +
      `&subset=${ filter.subset }` +
      `&customer=${ (filter.client === "all" || filter.client === undefined) ? "" : filter.client }` +
      `&date_begin=${ formatDateForApi(filter.date_begin) }` +
      `&date_end=${ formatDateForApi(filter.date_end) }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }`,
      this.getConfig(),
    );
  },
  getContracts: async function (filter: IContractsFilter, pagination: IPagination, sort: ISorting):
    Promise<{ data: { results: IShortContractorSource[], count: number } }> {
    return await api.get(`/contracts/` +
      `?search=${ filter.search || "" }` +
      `&category=${ filter.category || "" }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }` +
      `&limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }`,
      this.getConfig(),
    )
  },
  organizationInvitationRegister: async function (formData: IOrganizationRegister, token: string): Promise<{ data: any }> {
    return await api.post("organization/invitation/register/", {
      ...formData,
      token,
    }, this.getConfig());
  },
  contractInvitationCheck: async function (token: string): Promise<{ data: any }> {
    return await api.post("/contracts/contract/invitation/check/", {
      token,
    }, this.getConfig());
  },
  contractInvitationInfo: async function (token: string): Promise<{ data: any }> {
    return await api.post("/contracts/contract/invitation/info/", {
      token,
    }, this.getConfig());
  },
  contractInvitationAccept: async function (token: string): Promise<{ data: any }> {
    return await api.post("/contracts/contract/invitation/accept/", {
      token,
    }, this.getConfig());
  },
  contractInvitationReject: async function (token: string): Promise<{ data: any }> {
    return await api.post("/contracts/contract/invitation/reject/", {
      token,
    }, this.getConfig());
  },
  contractInvitationRefresh: async function (token: string): Promise<{ data: any }> {
    return await api.post("/contracts/contract/invitation/refresh/", {
      token,
    }, this.getConfig());
  },
  initContract: async function({
    contractor,
    identifier,
    me_type,
    contractor_type,
    sla,
    contract_category,
  }: {
    contractor: number,
    identifier: string,
    me_type: string,
    contractor_type: string,
    sla: string,
    contract_category: string | undefined,
  }): Promise<{ data: IContract }> {
    return await api.post("/contracts/contract/init/", {
      identifier,
      me_type: me_type,
      contractor_type: me_type === "sp" ? "customer" : "sp",
      sla,
      contractor,
      contract_category,
    }, this.getConfig());
  },
  deserializeFileCheck: async function (file_id: number, contract_id?: number): Promise<{ data: any }> {
    return await api.post("/contracts/deserialize_file/check/", {
      file: file_id,
      contract: contract_id || null,
    }, this.getConfig());
  },
  deserializeFileProcess: async function (file: number, contract: number): Promise<{ data: any }> {
    return await api.post("/contracts/deserialize_file/process/", {
      file,
      contract,
    }, this.getConfig());
  },
  productsBulk: async function (products: any): Promise<{ data: any }> {
    return await api.post("/products/bulk/", products, this.getConfig());
  },
  getContract: async function (id: number): Promise<{ data: IContract }> {
    return await api.get(`/contracts/${ id }/`, this.getConfig());
  },
  getActionsLog: async function (caseId: string): Promise<{ data: ICaseActionsLog }> {
    return await api.get(`/cases/${ caseId }/actionslog/`, this.getConfig());
  },
  updContract: async function (id: number, data: any): Promise<{ data: IContract }> {
    return await api.patch(`/contracts/${ id }/`, data, this.getConfig());
  },
  publishContract: async function (contractId: number, email: string): Promise<{ data: any }> {
    return await api.post("/contracts/contract/publish/", {
      contract: contractId,
      email,
    }, this.getConfig());
  },
  terminateContract: async function (contractId: number): Promise<{ data: any }> {
    return await api.post("/contracts/contract/terminate/", {
      contract: contractId,
    }, this.getConfig());
  },
  getContractTemplateFile: async function (): Promise<{ data: string }> {
    return await api.get("/contracts/template_file/", this.getConfig());
  },
  getContractSla: async function (id: number): Promise<{ data: any }> {
    return await api.get(`/sla_contract/${ id }/`, this.getConfig());
  },
  setContractSla: async function (contractId: number, description: string): Promise<{ data: any }> {
    return await api.post("/sla_contract/", {
      contract: contractId,
      description,
    }, this.getConfig());
  },
  updContractSla: async function (slaId: number, description: string): Promise<{ data: any }> {
    return await api.patch(`/sla_contract/${ slaId }/`, {
      description,
    }, this.getConfig());
  },
  getContractEquipments: async function (
    id: number, filter: IContractEquipmentsFilter, sort: ISorting, limit: number, offset: number,
  ) : Promise<{ data: { results: IContractEquipment[], count: number } }> {
    return await api.get(`/contracts/${ id }/equipment/` +
      `?category=${ filter.type === "all" ? "" : filter.type }` +
      `&search=${ filter.search }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }` +
      `&limit=${ limit }` +
      `&offset=${ offset }`,
      this.getConfig(),
    )
  },
  setContractEquipments: async function (contractId: number, equipments: IEquipmentSource[]): Promise<any> {
    return await api.post(`/contracts/${ contractId }/equipment/update/`, equipments, this.getConfig());
  },
  getContractsInvitations: async function (filter: IListInvitationFilter, limit: number, offset: number):
    Promise<{ data: { results: IInvitationSource[], count: number } }> {
    return await api.get(
      `/contracts/invitations/` +
      `?limit=${ limit }` +
      `&offset=${ offset }` +
      `&type=${ filter.type }`,
      this.getConfig(),
    );
  },
  getSlaCi: async function (): Promise<{ data: { results: ISlaCi[], count: number }}> {
    return await api.get("/sla_ci/", this.getConfig());
  },
  createSlaCi: async function (
    name: string, marker: string, marker_bg_color?: string, marker_text_color?: string,
  ): Promise<{ data: { results: ISlaCi[], count: number }}> {
    return await api.post("/sla_ci/", {
      name,
      marker,
      marker_bg_color,
      marker_text_color,
    }, this.getConfig());
  },
  updateSlaCi: async function (
    id: number, name: string, marker: string, marker_bg_color?: string, marker_text_color?: string,
  ): Promise<{ data: { results: ISlaCi[], count: number }}> {
    return await api.patch(`/sla_ci/${ id }/`, {
      name,
      marker,
      marker_bg_color,
      marker_text_color,
    }, this.getConfig());
  },
  getNotifications: async function (): Promise<{ data: { results: INotification[] } }> {
    return await api.get("/notifications/", this.getConfig());
  },
  clearNotifications: async function (): Promise<void> {
    return await api.get("/notifications/viewed/", this.getConfig());
  },
  getNewClientsCount: async function (): Promise<{ data: number }> {
    return await api.get("/users/new/count/", this.getConfig());
  },
  getClients: async function (filter: IUserManagementFilter, pagination: IPagination, sort: ISorting)
    : Promise<{ data: { results: IClient[], count: number } }> {
    return await api.get(`/org/users/`  +
      `?search=${ filter.search }` +
      `&limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }`, this.getConfig());
    // return await api.get(`/users/` +
    //   `?is_verified${ filter.type === "blocked" ? "=False" : `__isnull=${ filter.type === "new" ? "True" : "False" }` }` +
    //   `&page=${ pagination.page }` +
    //   // `&sort_field=${ sort.column }` +
    //   // `&sort_direction=${ sort.direction }` +
    //   `&limit=${ pagination.rowsPerPage }` +
    //   `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }`, this.getConfig());
  },
  relevantUsers: async function (filter: IUserManagementFilter, pagination: IPagination)
    : Promise<{ data: { results: IClient[], count: number } }> {
    return await api.get(`/relevant_users/`  +
      `?search=${ filter.search }` +
      `&limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }`, this.getConfig());
  },
  usersInvite: async function (email: string): Promise<{ data: { detail: string } }> {
    return await api.post("/org/users/invite/", { email }, this.getConfig());
  },
  usersInviteLink: async function (): Promise<{ data: { expire_in_days?: number; url?: string; } }> {
    return await api.post("/org/users/invite/", null, this.getConfig());
  },
  usersRoles: async function (): Promise<{ data: any }> {
    return await api.get("/org/users/roles/", this.getConfig());
  },
  usersDelete: async function (userId: number): Promise<{ data: any }> {
    return await api.delete(`/org/users/${ userId }/`, this.getConfig());
  },
  usersArchived: async function (): Promise<{ data: any }> {
    return await api.get("/org/users/?status=blocked", this.getConfig());
  },
  usersReactivate: async function (userId: number): Promise<{ data: any }> {
    return await api.get(`/org/users/${ userId }/reactivate/`, this.getConfig());
  },
  userRole: async function (userId: number, role: string): Promise<{ data: any }> {
    return await api.patch(`/org/users/${ userId }/`, { role }, this.getConfig());
  },
  invitationCheck: async function (token: string): Promise<{ data: { detail: string } }> {
    return await api.post("/invitation/check/", { token }, this.getConfig());
  },
  invitationComplete: async function ({ token, first_name, last_name, email, phone_number, password }: IInvitationComplete): Promise<void> {
    return await api.post("/invitation/complete/", {
      token,
      first_name,
      last_name,
      email,
      phone_number,
      password,
    }, this.getConfig());
  },
  editClient: async function (client: IClient): Promise<void> {
    return await api.patch(`/users/${ client.id }/`, { phone_number: client.phone_number, role: client.role, customer: client.customer && +client.customer }, this.getConfig());
  },
  acceptClient: async function (clientId: string): Promise<void> {
    return await api.post("/users/verify/", { user_id: clientId, is_verified: true }, this.getConfig());
  },
  blockClient: async function (clientId: string): Promise<void> {
    return await api.post("/users/verify/", { user_id: clientId, is_verified: false }, this.getConfig());
  },
  editPhone: async function (phone: string): Promise<void> {
    return await api.patch("/user/info/", { phone_number: phone }, this.getConfig());
  },
  setContractAlias: async function (alias: string, contractId: number): Promise<{ data: string, status: number }> {
    return await api.post("/contracts/meta/", {
      alias,
      contract: contractId,
    }, this.getConfig());
  },
  setContractInformation: async function (information: string, contractId: number): Promise<{ data: string, status: number }> {
    return await api.post("/contracts/meta/", {
      information,
      contract: contractId,
    }, this.getConfig());
  },
  updateContractInformation: async function (id: number, information: string): Promise<void> {
    return await api.patch(`/contracts/${ id }/`, { information }, this.getConfig());
  },
  getEquipmentForCase: async function (serial_number: string, id?: number): Promise<{ data: ICaseEquipment | null }> {
    return await api.get(`/equipment/by_sn_id/?sn=${ serial_number }&id=${ id || "" }`, this.getConfig());

  },
  getContractorRelatedMyOrg: async function (contractor_pk: number): Promise<{ data: any }> {
    return await api.get(`/contractors/${ contractor_pk }/related_my_org`, this.getConfig());
  },
  openCase: async function (
    case_for_open: Partial<ICase>,
    contractor?: number,
    subcontractor?: number,
    equipment?: number,
  ): Promise<void> {
    let result: any = {
      contact_email: case_for_open.author_email,
      subject: case_for_open.subject,
      text: case_for_open.text,
      severity: case_for_open.severity,
      serial_number: case_for_open.serial_number,
      contact_phone_number: case_for_open.author_phone_number,
      email_cc: case_for_open.email_cc,
      contact_name: case_for_open.author_name,
      attachments: case_for_open.attachments,
      customer: contractor,
      service_provider: subcontractor,
      equipment,
    }

    return await api.post("/cases/create/", result, this.getConfig());
  },
  openPublicCase: async function (case_for_open: Partial<ICase>, contractor?: number, equipment?: number, contact_user?: number):
   Promise<void> {
    let result: any = {
      contact_email: case_for_open.author_email,
      subject: case_for_open.subject,
      text: case_for_open.text,
      severity: case_for_open.severity,
      serial_number: case_for_open.serial_number,
      contact_phone_number: case_for_open.author_phone_number,
      email_cc: case_for_open.email_cc,
      contact_name: case_for_open.author_name,
      attachments: case_for_open.attachments,
      equipment,
      contractor,
    }

    return await api.post("/cases/create/non-auth/", result, this.getConfig());
  },
  closeCase: async function (id: number, resolution: string): Promise<void> {
    return await api.post(`/cases/${ id }/close/`, {
      text: resolution,
    }, this.getConfig());
  },
  editCaseResolution: async function (id: number, text: string): Promise<void> {
    return await api.patch(`/cases/resolutions/${ id }/`, { text }, this.getConfig());
  },
  getContractEquipmentCategories: async function (): Promise<{ data: { results: { id: number, name: string }[] } }> {
    return await api.get("/product/categories/", this.getConfig());
  },
  deleteCategory: async function (categoryId: number): Promise<{ data: { results: { id: number, name: string }[] } }> {
    return await api.delete(`/product/categories/${ categoryId }`, this.getConfig());
  },
  getContractCategories: async function (): Promise<{ data: { results: { id: number, name: string }[] } }> {
    return await api.get("/contracts/categories/", this.getConfig());
  },
  deleteContractCategory: async function (productId: number): Promise<{ data: { results: { id: number, name: string }[] } }> {
    return await api.delete(`/contracts/categories/${ productId }`, this.getConfig());
  },
  productsReorder: async function (arr: any[]): Promise<void> {
    return await api.post("/contracts/categories/reorder/", arr, this.getConfig());
  },
  categoriesReorder: async function (arr: any[]): Promise<void> {
    return await api.post("/product/categories/reorder/", arr, this.getConfig());
  },
  createContractEquipmentCategory: async function (name: string): Promise<{ data: { results: { id: number, name: string }[] } }> {
    return await api.post("/product/categories/", {
      name,
    }, this.getConfig());
  },
  updateContractEquipmentCategory: async function (id: number, name: string): Promise<{
    data: { results: { id: number, name: string }[] }
  }> {
    return await api.put(`/product/categories/${ id }/`, {
      name,
    }, this.getConfig());
  },
  createContractCategory: async function (name: string): Promise<{ data: { results: { id: number, name: string }[] } }> {
    return await api.post("/contracts/categories/", {
      name,
    }, this.getConfig());
  },
  getSettingsEmailSender: async function () {
    return await api.get("/settings/email_sender/", this.getConfig());
  },
  getSettingsEmailSenderCheck: async function () {
    return await api.get("/settings/email_sender/check/", this.getConfig());
  },
  asyncTasksCheck: async function (taskId: string) {
    return await api.get(`/async_tasks/check/${ taskId }/`, this.getConfig());
  },
  createSettingsEmailSender: async function (data: any) {
    return await api.post("/settings/email_sender/", data, this.getConfig());
  },
  updateSettingsEmailSender: async function (data: any) {
    return await api.patch("/settings/email_sender/", data, this.getConfig());
  },
  getCustomers: async function (search: string): Promise<{ data: { results: ICustomerSource[] } }> {
    return await api.get(`/customers/?search=${ search }`, this.getConfig());
  },
  uploadFile: async function (file: File, type?: string): Promise<{ id: number }> {
    const startResponse = await api.post("/file/upload/direct/start/", {
      file_name: file.name,
      file_type: file.name.substring(file.name.lastIndexOf(".")),
      type,
    }, this.getConfig());
    const postData = new FormData();

    for (const key in startResponse.data.fields) {
      postData.append(key, startResponse.data.fields[ key ]);
    }

    postData.append("file", file);

    await api.post(
      startResponse.data.url,
      postData,
      {},
    );

    await api.post("/file/upload/direct/finish/", { file_id: startResponse.data.id }, this.getConfig());
    return { id: startResponse.data.id };
  },
  getStorage: async function (filter: IStorageFilter, limit: number, offset: number):
    Promise<{ data: { results: IStorageItem[], count: number } }> {
    return await api.get("/storage/equipment/" +
      `?search=${ filter.search }` +
      `&limit=${ limit }` +
      `&offset=${ offset }`, this.getConfig())
  },
  getCase: async function (id: string): Promise<{ data: ICase }> {
    return await api.get("/cases/" + id + "/", this.getConfig());
  },
  getEquipment: async function (id: string): Promise<{ data: IEquipmentSource }> {
    return await api.get("/equipment/" + id + "/", this.getConfig());
  },
  getEquipmentCases: async function (id: string): Promise<{ data: { results: [] } }> {
    return await api.get("/equipment/" + id + "/history/cases/", this.getConfig());
  },
  getEquipmentContracts: async function (id: string): Promise<{ data: { results: [] } }> {
    return await api.get("/equipment/" + id + "/history/contracts/", this.getConfig());
  },
  getProducts: async function (filter: IProductsFilter, limit: number, offset: number):
    Promise<{ data: { results: IProduct[], count: number } }> {
    return await api.get("/products/" +
      `?limit=${ limit }` +
      `&offset=${ offset }` +
      `&category=${ filter.category === null ? "" : filter.category }` +
      `&search=${ filter.search }`, this.getConfig());
  },
  addProduct: async function (product: IProduct): Promise<void> {
    return await api.post("/products/", {
      product_number: product.product_number,
      category: product.category.id,
      description: product.description,
      vendor: product.vendor,
    }, this.getConfig());
  },
  editProduct: async function (product: IProduct): Promise<void> {
    return await api.patch(`/products/${ product.id }/`, {
      ...product,
      category: product.category.id,
    }, this.getConfig());
  },
  getCaseComments: async function (caseId: number): Promise<{ data: IComment[] }> {
    return await new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: comments });
      }, 1000);
    })
  },
  getContractors: async function (filter: IContractorsFilter, limit: number, offset: number):
    Promise<{ data: { results: IContractor[], count: number } }> {
    return await api.get("/contractors/" +
      `?limit=${ limit }` +
      `&offset=${ offset }` +
      `&search=${ filter.search }`, this.getConfig());
  },
  getContractor: async function (id: number): Promise<{ data: IContractor }> {
    return await api.get(`/contractors/${ id }/`, this.getConfig());
  },
  addContractor: async function (contractor: Partial<IContractor>): Promise<void> {
    return await api.post("/contractors/", {
      default_email: contractor.default_email,
      name: contractor.name,
      official_name: contractor.official_name,
      phone_number: contractor.phone_number || null,
    }, this.getConfig());
  },
  editContractor: async function (contractor: Partial<IContractor>): Promise<void> {
    return await api.patch(`/contractors/${ contractor.id }/`, {
      default_email: contractor.default_email,
      name: contractor.name,
      official_name: contractor.official_name,
      phone_number: contractor.phone_number || null,
    }, this.getConfig());
  },
  contractsDownloadPdf: async function (id: number): Promise<AxiosResponse> {
    return await api.get(`/contracts/${ id }/download/pdf/`, { ...this.getConfig(), responseType: "blob" });
  },
  contractsDownloadXlsx: async function (id: number): Promise<AxiosResponse> {
    return await api.get(`/contracts/${ id }/download/xlsx/`, { ...this.getConfig(), responseType: "blob" });
  },
}

export default ApiService;
