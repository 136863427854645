import { useState } from "react";

import {
  MyProfile,
  SettingsHeader,
} from "components/settings";
import { useBreadcrumbs } from "context/useBreadcrumbs";
import { RouteLinks } from "services/router.service";

import "./MyProfileSettings.scss";

const MyProfileSettings = () => {
  const [ activeTab, setActiveTab ] = useState("profile");

  useBreadcrumbs([
    { url: RouteLinks.PROTECTED.SETTINGS.GENERAL, label: "Settings" },
    { label: "My profile" },
  ]);

  return (
    <div className="default-page-wrapper">
      <SettingsHeader
        activeTab={ activeTab }
        setActiveTab={ setActiveTab }
      />
      {activeTab === "profile" && <MyProfile />}

      {/*{activeTab === "email-sender" && <EmailSender />}*/}
    </div>
  );
};

export default MyProfileSettings;
