import { FC, Fragment, useState } from "react";

import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import appLogo from "data/icons/hydra-logo.svg";
import { authorizedPages } from "services/data.service";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { RouteLinks } from "services/router.service";
import arrowUpWhite from "data/icons/arrow-up-white.svg";

// import { getUserManagementSelector } from "store/userManagement/userManagement.selector";

import "./NavBar.scss";

const NavBar: FC = () => {
  const [ activeItem, setActiveItem ] = useState("");
  const { isMobile, isAdmin, organization, meta } = useAppSelector(getUserSelector);
  // const { newClientsCount } = useAppSelector(getUserManagementSelector);
  const loc = useLocation();
  const navigate = useNavigate();

  return !isMobile ? (
    <Layout.Sider className="navbar-wrapper">
      <div className="navbar-logo">
        <img alt="" src={ appLogo } />
      </div>

      {organization && (
        <div className="navbar-label">
          {organization.logo_url && <img height="35" src={ organization.logo_url } alt=""/>}

          {organization.name}
        </div>
      )}

      <div className="nav-wrapper">
        { authorizedPages.filter((page) => isAdmin
          || (page.link !== RouteLinks.PROTECTED.PRODUCTS))
          .map((link) => (
          <Fragment key={ link.link }>
            { (
              link.link === RouteLinks.PROTECTED.PRODUCTS ||
              link.link === RouteLinks.PROTECTED.SETTINGS.GENERAL
            ) && (
              <div className="divider" />
            ) }
            <div
              className={ `nav-item ${ loc.pathname.indexOf(link.link) !== -1 ? "active" : "" }` }
              key={ link.link }
              onClick={ () => {
                navigate(link.link);
              } }
            >
              <img src={ loc.pathname.indexOf(link.link) !== -1 ? link.blueIcon : link.icon } alt="" />
              { link.label }
              {/*{ link.link === RouteLinks.PROTECTED.USER_MANAGEMENT && newClientsCount > 0 && (*/}
              {/*  <p className="clients-count-value">{ newClientsCount }</p>*/}
              {/*) }*/}

              {meta?.[ link?.link?.split("/")[ 1 ] ] && (
                <div className="nav-item-dot"/>
              )}

              {link?.children && (
                <img
                  alt=""
                  className="nav-item-control"
                  src={ arrowUpWhite }
                  style={ { marginLeft: "auto", rotate: activeItem !== link.label ? "90deg" : "180deg" } }
                  onClick={ () => setActiveItem(activeItem !== link.label ? link.label : "") }
                />
              )}
            </div>

            {activeItem === link.label && link?.children?.map(el => (
              <div
                onClick={ () => navigate(el.link) }
                className="nav-item nav-item-child"
                key={ el.label }
              >
                {el.label} {activeItem === el.label}

                {meta?.[ link?.link?.split("/")[ 1 ] ] && (
                  <div className="nav-item-child-count">
                    {meta?.[ link?.link?.split("/")[ 1 ] ] && Object.values(meta?.[ link?.link?.split("/")[ 1 ] ])[ 0 ]}
                  </div>
                )}
              </div>
            ))}
          </Fragment>
        )) }
      </div>
    </Layout.Sider>
  ) : (
    <Layout.Footer className="footer-wrapper">
      <div className="footer-nav-wrapper">
        { authorizedPages.filter((page) => [ "Cases", "List of Equipment" ].includes(page.label)).map((page) => (
          <div
            className={ `nav-item ${ loc.pathname.indexOf(page.link) !== -1 ? "active" : "" }` }
            key={ page.link }
            onClick={ () => navigate(page.link) }
          >
            <img src={ loc.pathname.indexOf(page.link) !== -1 ? page.darkIcon : page.grayIcon } alt="" />
            { page.link === RouteLinks.PROTECTED.LIST_EQUIPMENT ? "Equipment" : page.label }
          </div>
        )) }
      </div>
    </Layout.Footer>
  )
}

export default NavBar;
