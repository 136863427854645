import { FC, useEffect } from "react";

import { useAppDispatch } from "store";
import { getContracts, getContractsCategories } from "store/contracts/contracts.thunks";
import { ContractsHeader, ContractsTable, ContractsFooter } from "components/contracts";
import { useBreadcrumbs } from "context/useBreadcrumbs";

const ContractsPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getContracts());
    dispatch(getContractsCategories());
  }, []);

  useBreadcrumbs([
    { label: "Contracts" },
  ]);

  return (
    <div className="default-page-wrapper">
      <ContractsHeader />
      <ContractsTable />
      <ContractsFooter />
    </div>
  )
}

export default ContractsPage;
