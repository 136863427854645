import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import { getClientsFail, getClientsRequest, getClientsSuccess, getNewClientsCountSuccess } from "./userManagement.actions";
import { IUserManagementState } from "./userManagement.types";
import api from "services/api.service";
import { formatPhoneNumber } from "services/data.service";

export const getClients = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  dispatch(getClientsRequest());
  try {
    const { filter, pagination, sort }: IUserManagementState = getState().userManagement;

    const response = await api.getClients(filter, pagination, sort);
    dispatch(getClientsSuccess(
      response.data.results.map((client) => ({
        ...client,
        phone_number: client.phone_number?.[ 0 ] === "+"
          ? formatPhoneNumber(client.phone_number)
          : client.phone_number,
      })),
      response.data.count,
    ));
  } catch (e: any) {
    dispatch(getClientsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getNewClientsCount = () => async (dispatch: TAppDispatch) => {
  try {
    const response = await api.getNewClientsCount();

    dispatch(getNewClientsCountSuccess(response.data))
  } catch (e: any) {
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}
