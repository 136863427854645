import { Tooltip } from "antd";

import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import hydraAccount from "data/icons/hydra-account.svg";
import verified from "data/icons/verified.svg";

import "./CompanyItem.scss";

interface ICompanyItem {
	email: string;
	has_account: boolean;
	has_verified_account: boolean;
	official_name: string;
	phone: string;
	value: string;
}

const CompanyItem = ({
	official_name,
	phone,
	email,
	has_account,
	has_verified_account,
	value,
}: ICompanyItem) => {
	return (
		<div className="company-item">
			{has_account ? (
				<Tooltip title="Has account">
					<img src={ hydraAccountBlue } alt="" />
				</Tooltip>
			) : (
				<Tooltip title="No account">
					<img src={ hydraAccount } alt="" />
				</Tooltip>
			)}

			{has_verified_account && <img src={ verified } alt="" />}

			<Tooltip
				title={ (
					<div>
						<div>{ official_name }</div>
						<div>{ phone }</div>
						<div>{ email }</div>
					</div>
				) }
			>
				<p>{value}</p>
			</Tooltip>
		</div>
	);
};

export default CompanyItem;
