import { useState } from "react";

import { Button, Form, Input, message } from "antd";

import editIcon from "data/icons/edit-blue.svg";
import phoneIcon from "data/icons/phone.svg";
import { formatPhoneNumber } from "services/data.service";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { getUserData } from "store/user/user.thunks";
import api from "services/api.service";

import "./MyProfile.scss";

const MyProfile = () => {
	const [ form ] = Form.useForm();

	const { user } = useAppSelector(getUserSelector);
	const dispatch = useAppDispatch();

	const [ isEditProfile, setIsEditProfile ] = useState(false);
	const [ isEditPassword, setIsEditPassword ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);

	const newPassword = Form.useWatch("newPassword", form);
	const confirmPassword = Form.useWatch("confirmPassword", form);

	const isPasswordSubmitDisabled = newPassword?.length < 8 || (newPassword !== confirmPassword) || isLoading;

	const handleProfileSave = async (values: any) => {
		setIsEditProfile(false);

		try {
			const response = await api.setUserData(values);

			if (response) {
				dispatch(getUserData(() => {}));
			}
		} catch (error) {
			message.error("Something went wrong!");
		}
	};

	const handlePasswordSave = async (values: {
		oldPassword: string;
		newPassword: string;
	}) => {
		setIsLoading(true);

		try {
			await api.setUserPassword({
				old_password: values.oldPassword,
				new_password: values.newPassword,
			});

			setIsEditPassword(false);
		} catch (error) {
			// @ts-ignore
			message.error(error?.response?.data?.detail || "Something went wrong!");
		}

		setIsLoading(false);
	};

	if (!user) {
		return null;
	}

	return (
		<div>
			{isEditProfile ? (
				<div className="section">
					<div className="section-header">
						<span>Personal information</span>
					</div>

					<Form
						layout="vertical"
						initialValues={ user }
						form={ form }
						onFinish={ handleProfileSave }
					>
						<div className="section-row">
							<Form.Item
								style={ { width: "300px" } }
								label="First name"
								name="first_name"
								rules={ [ { required: true, message: "Please input First name!" } ] }
							>
								<Input />
							</Form.Item>

							<Form.Item
								style={ { width: "300px" } }
								label="Last name"
								name="last_name"
								rules={ [ { required: true, message: "Please input Last name!" } ] }
							>
								<Input />
							</Form.Item>
						</div>

						<div className="section-row">
							<Form.Item
								style={ { width: "300px" } }
								label="Email address"
								name="email"
							>
								<Input disabled={ true } />
							</Form.Item>

							<Form.Item
								style={ { width: "300px" } }
								label="Phone"
								name="phone_number"
							>
								<Input
									placeholder="+375 (00) 000-00-00"
									prefix={ <img src={ phoneIcon } alt="" style={ { marginRight: "5px" } } /> }
									onChange={ (e) => form.setFieldsValue({ phone_number: formatPhoneNumber(e.target.value) }) }
								/>
							</Form.Item>
						</div>

						<div className="section-buttons">
							<Button
								style={ { width: "120px" } }
								onClick={ () => setIsEditProfile(false) }
							>
								Cancel
							</Button>

							<Button
								htmlType="submit"
								type="primary"
								style={ { width: "120px" } }
								// onClick={ handleProfileSave }
							>
								Save
							</Button>
						</div>
					</Form>
				</div>
			) : (
				<div className="section">
					<div className="section-header">
						<span>Personal information</span>

						<Button
							className="profile-edit-button"
							onClick={ () => setIsEditProfile(true) }
						>
							Edit
							<img src={ editIcon } alt="" />
						</Button>
					</div>

					<div className="section-row">
						<div className="section-group">
							<div className="section-group-title">
								First name
							</div>

							<div className="section-group-text">
								{user.first_name}
							</div>
						</div>

						<div className="section-group">
							<div className="section-group-title">
								Last name
							</div>

							<div className="section-group-text">
								{user.last_name}
							</div>
						</div>
					</div>

					<div className="section-row">
						<div className="section-group">
							<div className="section-group-title">
								Email address
							</div>

							<div className="section-group-text">
								{user.email}
							</div>
						</div>

						<div className="section-group">
							<div className="section-group-title">
								Phone
							</div>

							<div className="section-group-text">
								{user.phone_number || "-"}
							</div>
						</div>
					</div>
				</div>
			)}

			{isEditPassword ? (
				<div className="section">
					<div className="section-header">
						<span>Password</span>
					</div>

					<Form
						className="contractor-form"
						layout="vertical"
						form={ form }
						onFinish={ handlePasswordSave }
					>
						<div className="section-row">
							<div className="section-row-closer">
								<Form.Item
									style={ { width: "300px" } }
									label="Old password"
									name="oldPassword"
									rules={ [ { required: true, message: "Please input old password!" } ] }
								>
									<Input.Password />
								</Form.Item>
							</div>

							<div className="section-row-closer">
								<Form.Item
									style={ { width: "300px" } }
									label="New password"
									name="newPassword"
									rules={ [ { required: true, message: "Please input new password!" } ] }
								>
									<Input.Password />
								</Form.Item>

								<Form.Item
									style={ { width: "300px" } }
									label="Comfirm new password"
									name="confirmPassword"
									rules={ [ { required: true, message: "Please input confirm password!" } ] }
								>
									<Input.Password placeholder="enter your confirm new password" />
								</Form.Item>
							</div>
						</div>

						<div className="section-buttons">
							<Button
								style={ { width: "120px" } }
								onClick={ () => setIsEditPassword(false) }
							>
								Cancel
							</Button>

							<Button
								disabled={ isPasswordSubmitDisabled }
								htmlType="submit"
								type="primary"
								style={ { width: "150px" } }
							>
								Update password
							</Button>
						</div>
					</Form>
				</div>
			) : (
				<div className="section">
					<div className="section-header">
						<span>Password</span>
					</div>

					<Button
						type="primary"
						onClick={ () => setIsEditPassword(true) }
					>
						Change password
					</Button>
				</div>
			)}
		</div>
	);
};

export default MyProfile;
