import { useEffect, useState } from "react";

import { Button, message, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import api from "services/api.service";
import { IClient } from "store/userManagement/userManagement.types";

const TeamArchive = () => {
	const [ archivedUsers, setArchivedUsers ] = useState<IClient[]>([]);

	const onLoadArchivedUsers = async () => {
		try {
			const { data } = await api.usersArchived();

			setArchivedUsers(data.results);
		} catch (e: any) {
			message.error(e.response.data.error || e.error || "Something went wrong!");
		}
	};

	const onUserReactivate = async (userId: number) => {
		try {
			const response = await api.usersReactivate(userId);

			message.success(response.data.detail);

			onLoadArchivedUsers();
		} catch (e: any) {
			message.error(e.response.data.error || e.error || "Something went wrong!");
		}
	};

	useEffect(() => {
		onLoadArchivedUsers();
	}, []);

	const columns: ColumnsType<IClient> = [
		{
			title: "First Name",
			dataIndex: "first_name",
			key: "first_name",
			render: (value) => <p className="active thin">{ value }</p>,
			width: "9%",
		},
		{
			title: "Last Name",
			dataIndex: "last_name",
			key: "last_name",
			width: "9%",
			render: (value) => <p className="active thin">{ value }</p>,
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			width: "16%",
			render: (value) => <p className="active thin">{ value }</p>,
		},
		{
			title: "Phone",
			dataIndex: "phone_number",
			key: "phone_number",
			width: "14%",
		},
		{
			title: "",
			dataIndex: "action_delete",
			key: "action_delete",
			width: "2%",
			render: (value, item) => (
				<Button
					type="text"
					size="small"
					onClick={ () => onUserReactivate(Number(item.id)) }
				>
					Activate
				</Button>
			),
		},
	];

	return (
		<Table
			className="cases-table"
			columns={ columns }
			dataSource={ archivedUsers }
			tableLayout="auto"
			style={ { marginTop: 32 } }
		/>
	);
};

export default TeamArchive;
