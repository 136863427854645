import { Pagination } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getUserManagementSelector } from "store/userManagement/userManagement.selector";
import { updateClientsPagination } from "store/userManagement/userManagement.actions";
import { getClients } from "store/userManagement/userManagement.thunks";

const TeamPagination = () => {
  const dispatch = useAppDispatch();
  const { pagination } = useAppSelector(getUserManagementSelector);

  const handleUpdatePagination = (page: number, pageSize: number) => {
    dispatch(updateClientsPagination(page, pageSize));
    dispatch(getClients());
  };

  return (
    <Pagination
      total={ pagination.count }
      showTotal={ (total, range) => `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
      pageSize={ pagination.rowsPerPage }
      current={ pagination.page }
      onChange={ handleUpdatePagination }
    />
  );
};

export default TeamPagination;
