import { useState } from "react";

import { Input, message, Tooltip } from "antd";

import { IContract } from "store/contracts/contracts.types";
import { Loading, StatusItem } from "components/common";
import handshakeBlueIcon from "data/icons/handshake-blue.svg";
import handshakeIcon from "data/icons/handshake.svg";
import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import hydraAccount from "data/icons/hydra-account.svg";
import checkIcon from "data/icons/check.svg";
import closeIcon from "data/icons/close.svg";
import editIcon from "data/icons/edit.svg";
import api from "services/api.service";

import "./ContractItemMobile.scss";

interface IContractItemMobileProps {
	item: IContract;
}

const ContractItemMobile = ({ item }: IContractItemMobileProps) => {
	const [ isEditingAlias, setIsEditingAlias ] = useState(false);

	const [ alias, setAlias ] = useState(item.meta.alias);
	const [ prevAlias, setPrevAlias ] = useState(item.meta.alias);

	const handleAlias = async () => {
		try {
			await api.setContractAlias(alias, item.id);
		} catch (e: any) {
			message.error(e.response.data.error || e.error || "Something went wrong!");
		}
	};

	const onEdit = () => {
		setPrevAlias(alias);
		setIsEditingAlias(true);
	};

	const onComplete = () => {
		handleAlias();
		setPrevAlias(alias);
		setIsEditingAlias(false);
	};

	const onCancel = () => {
		setIsEditingAlias(false);
		setAlias(prevAlias);
	};

	return (
		<div className="contract-item-mobile">
			<div className="contract-item-mobile-title">
				<StatusItem
					is_active={ item.is_active }
					is_pending={ item.is_pending }
				/>

				{item.handshake_completed ? (
					<Tooltip title="Handshaked">
						<img src={ handshakeBlueIcon } alt="" />
					</Tooltip>
				) : (
					<Tooltip title="Not handshaked">
						<img src={ handshakeIcon } alt="" />
					</Tooltip>
				)}

				<span className="contract-item-mobile-title-text">
					{item.identifier}
				</span>
			</div>

			<div>
				{isEditingAlias ? (
					<Loading
						isLoading={ false }
						isColored
					>
						<Input
							className="alias-input"
							value={ alias }
							onChange={ (e) => setAlias(e.target.value) }
							suffix={ (
								<>
									<img
										alt=""
										height={ 14 }
										width={ 14 }
										src={ checkIcon }
										onClick={ onComplete }
									/>

									<img
										alt=""
										onClick={ onCancel }
										src={ closeIcon }
									/>
								</>
							) }
							onClick={ (e) => e.stopPropagation() }
						/>
					</Loading>
				) : (
					<div className="alias-wrapper">
						{!alias ? (
							<div
								className="add-title"
								onClick={ onEdit }
							>
								<img
									alt=""
									className="edit-button"
									src={ editIcon }
								/>
								Add
							</div>
						) : (
							<span
								className={ `alias-value ${ item?.is_active ? "alias-active" : "" }` }
								onClick={ onEdit }
							>
								{alias}

								<img
									onClick={ onEdit }
									className="edit-button"
									src={ editIcon }
									alt=""
								/>
							</span>
						)}
					</div>
				)}
			</div>

			<div className="contract-item-mobile-footer">
				{item.contractor.has_account ? (
					<Tooltip title="Has account">
						<img src={ hydraAccountBlue } alt="" />
					</Tooltip>
				) : (
					<Tooltip title="No account">
						<img src={ hydraAccount } alt="" />
					</Tooltip>
				)}

				<span>
					{item.contractor.official_name}
				</span>
			</div>
		</div>
	);
};

export default ContractItemMobile;
