import { FC, Fragment, useContext, useState } from "react";

import { Header } from "antd/es/layout/layout";
import { Link, useLocation } from "react-router-dom";
import { Button, Modal } from "antd";

import { RouteLinks } from "services/router.service";
import loginBlueIcon from "data/icons/login-blue.svg";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { ContactUsModal, LoginForm, Notifications, UserMenu } from "components/common";
import { CreateCaseModal } from "components/cases";
import { LayoutContext } from "context/LayoutContext";
// import { authorizedPages } from "services/data.service";

import "./TopBar.scss";

const TopBar: FC = () => {
  const loc = useLocation();

  const { isAuthorized, isMobile } = useAppSelector(getUserSelector);

  const { breadcrumbs } = useContext(LayoutContext);

  const [ isLoginOpen, setIsLoginOpen ] = useState(false);
  const [ isContactUsOpen, setIsContactUsOpen ] = useState(false);

  // const extraLinks = [
  //   {
  //     value: RouteLinks.PROTECTED.DOWNLOAD_ROUTES.ADD_FILE,
  //     title: "Add File",
  //   },
  //   {
  //     value: RouteLinks.PROTECTED.DOWNLOAD_ROUTES.FILE,
  //     title: "File",
  //   },
  //   {
  //     value: RouteLinks.PROTECTED.CASES.CASE,
  //     title: "Case",
  //   },
  //   {
  //     value: RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT,
  //     title: currentContract ? currentContract?.identifier : "Loading...",
  //   },
  //   {
  //     value: RouteLinks.PROTECTED.CONTRACTORS.NEW,
  //     title: "Add contractor",
  //   },
  //   {
  //     value: RouteLinks.PROTECTED.CONTRACTS_ROUTES.INVITATION,
  //     title: "Invitations",
  //   },
  // ]

  return (
    <Header className={ `app-header ${ isMobile ? "mobile" : "" }` }>
      { isAuthorized ? (
        <>
          { !isMobile ? (
            <p className="page-title page-title-contract">
              {breadcrumbs.map(({ url, label }, index, array) => index < array.length - 1 ? (
                <Fragment key={ index }>
                  {url ? (
                    <Link
                      className="page-title-link"
                      to={ url }
                    >
                      {label}
                    </Link>
                  ) : (
                    <span className="page-title-label">
                      {label}
                    </span>
                  )}

                  <span className="page-title-divider">/</span>
                </Fragment>
              ) : (
                <span
                  className="page-title-label"
                  key={ index }
                >
                  {label}
                </span>
              ))}
            </p>
          ) : (
            <>
              <UserMenu/>
              <Notifications />
            </>
          ) }

          <div className="create-case-wrapper">
            <CreateCaseModal />
          </div>

          { !isMobile && (
            <>
              <Notifications />
              <UserMenu />
            </>
          ) }
        </>
        ) : (
        <>
          { (
            loc.pathname !== RouteLinks.AUTH &&
            loc.pathname !== RouteLinks.INVITE_COMPLETE &&
            loc.pathname !== RouteLinks.INVITE_ORGANIZATION_COMPLETE &&
            loc.pathname !== RouteLinks.INVITE_NEW_ORGANIZATION_COMPLETE
          ) && (
            <a
              className="login-button"
              style={ {
                margin: "10px 20px 0 auto",
              } }
            >
              <Button
                type="default"
                onClick={ () => setIsLoginOpen(true) }
              >
                <img src={ loginBlueIcon } alt=""/>
                Log in
              </Button>
            </a>
          ) }
        </>
      ) }

      <ContactUsModal
        isContactUsOpen={ isContactUsOpen }
        setIsContactUsOpen={ setIsContactUsOpen }
      />

      <Modal
        open={ isLoginOpen }
        onCancel={ () => setIsLoginOpen(false) }
        footer={ null }
        width={ 440 }
      >
        { isLoginOpen && (
          <div style={ { padding: "24px 16px 8px 16px" } }>
            <LoginForm onSuccess={ () => setIsLoginOpen(false) }/>
          </div>
        ) }
      </Modal>
    </Header>
  )
}

export default TopBar;
