import { useCallback, useEffect, useState } from "react";

import { Button, Form, Input, message } from "antd";
import { useDropzone } from "react-dropzone";

import phoneIcon from "data/icons/phone.svg";
import { formatPhoneNumber } from "services/data.service";
import editIcon from "data/icons/edit-blue.svg";
import api from "services/api.service";
import { useLoading } from "helpers/useLoading";
import { Snackbar } from "components/common";
import { useCompanyData } from "helpers/useCompanyData";

import "./CompanyDetails.scss";

const CompanyDetails = () => {
	const [ form ] = Form.useForm();
	const [ files, setFiles ] = useState<File[]>([]);

	const [ isGeneralInfoEdit, setIsGeneralInfoEdit ] = useState(false);

	const { company,  setCompany, error } = useCompanyData();

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: useCallback((acceptedFiles: File[]) => {
			let result: File[] = [];
			acceptedFiles && Object.keys(acceptedFiles).forEach((label) => {
				result.push(acceptedFiles![ +label ])
			})
			setFiles([ ...result ]);
		}, []),
		multiple: false,
	})

	const [ onFileUpload ] = useLoading(async () => {
		try {
			const response = await api.uploadFile(files[ 0 ], "company");

			if (response.id) {
				try {
					const response2 = await api.setCompanyData({
						logo: response.id,
					});

					if (response2 && response2.data) {
						setCompany(response2.data);
					}
				} catch (err) {
					message.error("Something went wrong!");
				}
			} else {
				message.error("Something went wrong!");
			}
		} catch (e) {
			message.error("Something went wrong!");
		}
	});

	const handleRemoveAvatar = async () => {
		try {
			const response = await api.setCompanyData({
				logo: null,
			});

			if (response) {
				setCompany(response.data);
			}
		} catch (err) {
			message.error("Something went wrong!");
		}
	};

	const handleCompanyDetailsSave = async (values: any) => {
		try {
			const response = await api.setCompanyData(values);
			setIsGeneralInfoEdit(false);

			if (response && response.data) {
				setCompany(response.data);
			}
		} catch (err) {
			message.error("Something went wrong!");
		}
	};

	useEffect(() => {
		if (files.length !== 0) {
			onFileUpload();
		}
	}, [ files ]);

	if (error) {
		return (
			<Snackbar type="info">
				Only administrators have access to these settings.
			</Snackbar>
		);
	}

	if (!company) {
		return null;
	}

	return (
		<div>
			<div className="section">
				<div className="section-header">
					<span>Company logo</span>
				</div>

				<div className="subtitle">
					Update your company logo
				</div>

				<div className="logo">
					{company.logo_url ? (
						<img
							alt=""
							className="logo-img"
							src={ company.logo_url }
						/>
					) : (
						<div className="noLogo" />
					)}

					<div className="section-buttons">
						<Button
							{ ...getRootProps({ className: "dropzone" }) }
							style={ { width: "120px" } }
						>
							<input { ...getInputProps() } />
							{isDragActive ? (
								<>Drop here</>
							) : (
								<>{company.logo_url ? "Replace" : "Upload"} logo</>
							)}

						</Button>

						<Button
							disabled={ !company.logo_url }
							htmlType="submit"
							type="primary"
							style={ { width: "120px" } }
							onClick={ handleRemoveAvatar }
						>
							Delete logo
						</Button>
					</div>
				</div>
			</div>

			{isGeneralInfoEdit ? (
				<div className="section">
					<div className="section-header">
						<span>General info</span>
					</div>

					<Form
						className="contractor-form"
						layout="vertical"
						form={ form }
						initialValues={ company }
						onFinish={ handleCompanyDetailsSave }
					>
						<div className="section-row">
							<Form.Item
								style={ { width: "300px" } }
								label="Official name"
								name="official_name"
								rules={ [ { required: true, message: "Please input Official name!" } ] }
							>
								<Input />
							</Form.Item>

							<Form.Item
								style={ { width: "300px" } }
								label="Short name"
								name="name"
								rules={ [ { required: true, message: "Please input Short name!" } ] }
							>
								<Input />
							</Form.Item>
						</div>

						<div className="section-row">
							<Form.Item
								style={ { width: "300px" } }
								label="Email address"
								name="default_email"
								rules={ [
									{ required: true, message: "Please input user email!" },
									{ type: "email", message: "Please input correct email!" },
								] }
							>
								<Input />
							</Form.Item>

							<Form.Item
								style={ { width: "300px" } }
								label="Phone"
								name="phone_number"
							>
								<Input
									placeholder="+375 (00) 000-00-00"
									prefix={ <img src={ phoneIcon } alt="" style={ { marginRight: "5px" } } /> }
									onChange={ (e) => form.setFieldsValue({ phone_number: formatPhoneNumber(e.target.value) }) }
								/>
							</Form.Item>
						</div>

						<div className="section-buttons">
							<Button
								style={ { width: "120px" } }
								onClick={ () => setIsGeneralInfoEdit(false) }
							>
								Cancel
							</Button>

							<Button
								htmlType="submit"
								type="primary"
								style={ { width: "120px" } }
							>
								Save
							</Button>
						</div>
					</Form>
				</div>
			) : (
				<div className="section">
					<div className="section-header">
						<span>General info</span>

						<Button
							className="profile-edit-button"
							onClick={ () => setIsGeneralInfoEdit(true) }
						>
							Edit
							<img src={ editIcon } alt="" />
						</Button>
					</div>

					<div className="section-row">
						<div className="section-group">
							<div className="section-group-title">
								Official name
							</div>

							<div className="section-group-text">
								{company.official_name}
							</div>
						</div>

						<div className="section-group">
							<div className="section-group-title">
								Short name
							</div>

							<div className="section-group-text">
								{company.name}
							</div>
						</div>
					</div>

					<div className="section-row">
						<div className="section-group">
							<div className="section-group-title">
								Email address
							</div>

							<div className="section-group-text">
								{company.default_email}
							</div>
						</div>

						<div className="section-group">
							<div className="section-group-title">
								Phone
							</div>

							<div className="section-group-text">
								{company.phone_number}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CompanyDetails;
