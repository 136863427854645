import { Reducer } from "react";

import { IListInvitationState, TListInvitationAction } from "./invitations.types";
import { ActionTypes } from "./invitations.actions";
import { extraPortion } from "services/data.service";

export const initialState: IListInvitationState = {
  filter: {
    type: "inbox",
  },
  pagination: {
    page: 1,
    rowsPerPage: extraPortion,
    count: 0,
  },
  invitations: [],
  isInvitationsLoading: false,
  isExtraInvitationsLoading: false,
  sort: {
    column: "id",
    direction: "ascend",
  },
}

const listInvitationReducer: Reducer<IListInvitationState, TListInvitationAction>
  = (prevState = initialState, action: TListInvitationAction) => {
  switch (action.type) {
    case ActionTypes.GET_INVITATIONS_REQUEST: {
      return {
        ...prevState,
        isInvitationsLoading: true,
      }
    }
    case ActionTypes.GET_INVITATIONS_SUCCESS: {
      return {
        ...prevState,
        isInvitationsLoading: false,
        invitations: action.payload.invitations,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    }
    case ActionTypes.GET_INVITATIONS_FAIL: {
      return {
        ...prevState,
        isInvitationsLoading: false,
      }
    }
    case ActionTypes.UPDATE_INVITATIONS_FILTER: {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          ...action.payload,
        },
      }
    }
    case ActionTypes.UPDATE_INVITATIONS_PAGINATION: {
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          ...action.payload,
        },
      }
    }
    case ActionTypes.GET_EXTRA_INVITATIONS_REQUEST:
      return {
        ...prevState,
        isExtraInvitationsLoading: true,
      }
    case ActionTypes.GET_EXTRA_INVITATIONS_SUCCESS:
      return {
        ...prevState,
        isExtraInvitationsLoading: false,
        invitations: [
          ...prevState.invitations,
          ...action.payload.invitations,
        ],
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
          rowsPerPage: prevState.pagination.rowsPerPage + extraPortion,
        },
      }
    case ActionTypes.GET_EXTRA_INVITATIONS_FAIL:
      return {
        ...prevState,
        isExtraInvitationsLoading: false,
      }
    default:
      return prevState;
  }
}

export default listInvitationReducer;
