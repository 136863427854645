import {
  ContractInvitationsHeader,
  ContractInvitationsTable,
  ContractInvitationsPagination,
} from "components/contracts";
import { useBreadcrumbs } from "context/useBreadcrumbs";
import { RouteLinks } from "services/router.service";

const ContractInvitationsPage = () => {
  useBreadcrumbs([
    { url: RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN, label: "Contracts" },
    { label: "Invitations" },
  ]);

  return (
    <div className="default-page-wrapper">
      <ContractInvitationsHeader/>
      <ContractInvitationsTable />
      <ContractInvitationsPagination />
    </div>
  );
};

export default ContractInvitationsPage;
