import { useState } from "react";

import {
  CompanyDetails,
  SettingsHeader,
} from "components/settings";
import { useBreadcrumbs } from "context/useBreadcrumbs";
import { RouteLinks } from "services/router.service";

import "./CompanyDetailsSettings.scss";

const CompanyDetailsSettings = () => {
  const [ activeTab, setActiveTab ] = useState("company-details");

  useBreadcrumbs([
    { url: RouteLinks.PROTECTED.SETTINGS.GENERAL, label: "Settings" },
    { label: "Company details" },
  ]);

  return (
    <div className="default-page-wrapper">
      <SettingsHeader
        activeTab={ activeTab }
        setActiveTab={ setActiveTab }
      />
      {activeTab === "company-details" && <CompanyDetails />}
    </div>
  );
};

export default CompanyDetailsSettings;
