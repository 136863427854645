import { FC } from "react";

import { Button, Pagination } from "antd";

import downloadIcon from "data/icons/downloads-white.svg";
import { useAppDispatch, useAppSelector } from "store";
import { getCasesSelector } from "store/cases/cases.selector";
import { updateCasesPagination } from "store/cases/cases.actions";
import { getCases } from "store/cases/cases.thunks";
import { getUserSelector } from "store/user/user.selector";
import { Loading } from "components/common";

const CasesFooter: FC = () => {
	const dispatch = useAppDispatch();
	const { pagination, isCasesLoading } = useAppSelector(getCasesSelector);
	const { isMobile } = useAppSelector(getUserSelector);

	const handleUpdatePagination = (page: number, pageSize: number) => {
		dispatch(updateCasesPagination({
			page: page,
			rowsPerPage: pageSize,
		}))
		dispatch(getCases());
	}

	if (pagination.count === 0) {
		return null;
	}

	return (
		<Loading isLoading={ isCasesLoading } isColored height={ 200 }>
			<div className={ `default-footer-wrapper ${ isMobile ? "mobile" : "" }` }>
				{/*<Button type="primary">*/}
				{/*	{ isMobile ? "" : "Download cases" }*/}
				{/*	<img src={ downloadIcon } alt="" />*/}
				{/*</Button>*/}

				<Pagination
					total={ pagination.count }
					showTotal={ (total, range) => isMobile ? "" : `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
					pageSize={ pagination.rowsPerPage }
					current={ pagination.page }
					onChange={ handleUpdatePagination }
					// responsive
				/>
			</div>
		</Loading>
	)
}

export default CasesFooter;
