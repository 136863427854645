import { useNavigate } from "react-router-dom";
import { Tabs, TabsProps } from "antd";

import "./SettingsHeader.scss";

interface IProps {
	activeTab: string;
	setActiveTab: (value: string) => void;
}

const SettingsHeader = ({ activeTab, setActiveTab }: IProps) => {
	const navigate = useNavigate();

	const onChangeTab = (value: string) => {
		setActiveTab(value);

		navigate(`/settings/${ value }`);
	};

	const tabs: TabsProps[ "items" ] = [
		{
			key: "general",
			label: "General settings",
		},
		{
			key: "profile",
			label: "My profile",
		},
		{
			key: "company-details",
			label: "Company details",
		},
		{
			key: "team",
			label: "Team",
		},
		// {
		//   key: "password",
		//   label: "Password",
		// },
		// {
		//   key: "help-support",
		//   label: "Help & Support",
		// },
		// {
		//   key: "notifications",
		//   label: "Notifications",
		// },
		// {
		//   key: "email-sender",
		//   label: "Email Sender",
		// },
	];

	return (
		<div className="settings-header">
			<Tabs
				className="settings-header-tabs"
				items={ tabs }
				activeKey={ activeTab }
				onChange={ onChangeTab }
				animated={ false }
			/>
		</div>
	);
};

export default SettingsHeader;
