import { useState } from "react";

import { Button, Modal } from "antd";

import TeamFilter from "./TeamFilter";
import TeamTable from "./TeamTable";
import TeamPagination from "./TeamPagination";
import TeamArchive from "./TeamArchive";
import { Snackbar } from "components/common";
import { useCompanyData } from "helpers/useCompanyData";

const Team = () => {
  const [ isArchiveModal, setIsArchiveModal ] = useState(false);

  const { error } = useCompanyData();

  return (
    <>
      {error ? (
        <Snackbar type="info">
          Only administrators have access to invite new users.
        </Snackbar>
      ) : (
        <TeamFilter />
      )}

      <TeamTable />

      <div className="default-footer-wrapper">
        <Button onClick={ () => setIsArchiveModal(true) }>
          Archive
        </Button>

        <TeamPagination />
      </div>

      <Modal
        open={ isArchiveModal }
        onCancel={ () => setIsArchiveModal(false) }
        title="Archived users"
        footer={ false }
        width={ 1000 }
      >
        <TeamArchive />
      </Modal>
    </>
  );
};

export default Team;
