import React, { createContext } from "react";

export interface IBreadcrumb {
	url?: string
	label: string
}

interface ILayoutContext {
	breadcrumbs: IBreadcrumb[]
	setBreadcrumbs: React.Dispatch<React.SetStateAction<IBreadcrumb[]>>
}

export const LayoutContext = createContext<ILayoutContext>({
	breadcrumbs: [],
	setBreadcrumbs(value: ((prevState: IBreadcrumb[]) => IBreadcrumb[]) | IBreadcrumb[]): void {},
});
