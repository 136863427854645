import { FC, useEffect } from "react";

import { useAppDispatch } from "store";
import { getProducts, getProductsCategories } from "store/products/products.thunks";
import { updateProductsFilter, updateProductsPagination } from "store/products/products.actions";
import { initialState } from "store/products/products.reducer";
import { ProductsFooter, ProductsHeader, ProductsTable } from "components/products";
import { useBreadcrumbs } from "context/useBreadcrumbs";

const ProductsPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getProductsCategories());

    return () => {
      dispatch(updateProductsPagination(initialState.pagination.page));
      dispatch(updateProductsFilter(initialState.filter));
    }
  }, [])

  useBreadcrumbs([
    { label: "Products" },
  ]);

  return (
    <div className="default-page-wrapper">
      <ProductsHeader />
      <ProductsTable />
      <ProductsFooter />
    </div>
  )
}

export default ProductsPage;
