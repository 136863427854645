import { FC, useEffect } from "react";

import { ListEquipmentFilter, ListEquipmentTable, ListEquipmentPagination } from "components/listEquipment";
import { useAppDispatch } from "store";
import { getEquipments } from "store/listEquipment/listEquipment.thunks";
import { useBreadcrumbs } from "context/useBreadcrumbs";

const ListEquipmentPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEquipments());
  }, []);

  useBreadcrumbs([
    { label: "List of Equipment" },
  ]);

  return (
    <div className="default-page-wrapper">
      <ListEquipmentFilter />
      <ListEquipmentTable />
      <ListEquipmentPagination />
    </div>
  )
}

export default ListEquipmentPage;
