import { FC, useEffect, useState } from "react";

import { AutoComplete, Button, Form, Input, message, Select, Tag } from "antd";
import { Link, useLocation } from "react-router-dom";

import emailIcon from "data/icons/email.svg";
import phoneIcon from "data/icons/phone.svg";
import phoneIconGray from "data/icons/phone-gray.svg";
import addFileIcon from "data/icons/add-file.svg";
import exclamationIcon from "data/icons/exclamation-circle.svg";
import exclamationIconRed from "data/icons/exclamation-circle-red.svg";
import infoIcon from "data/icons/info-circle.svg";
import infoIconBlue from "data/icons/info-circle-blue.svg";
import warningIcon from "data/icons/warning-circle.svg";
import emailIconGray from "data/icons/email-gray.svg";
import warningIconOrange from "data/icons/warning-circle-orange.svg";
import attachedFileIcon from "data/icons/attached-file.svg";
import closeIcon from "data/icons/close.svg";
import editIcon from "data/icons/edit.svg";
import hydraAccount from "data/icons/hydra-account.svg";
import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import { formatPhoneNumber } from "services/data.service";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { ICaseEquipment, Severity } from "store/cases/cases.types";
import api from "services/api.service";
import { ICaseForOpen } from "services/interface.service";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";
import { getCases } from "store/cases/cases.thunks";
import { useContractors } from "helpers/useContractors";

import "./CaseForm.scss";

const severities = [
	{
		value: 0,
		label: "Low",
		icon: infoIcon,
		iconActive: infoIconBlue,
		className: "custom-radio-item-info",
	},
	{
		value: 1,
		label: "Normal",
		icon: infoIcon,
		iconActive: infoIconBlue,
		className: "custom-radio-item-info",
	},
	{
		value: 2,
		label: "Degraded",
		icon: warningIcon,
		iconActive: warningIconOrange,
		className: "custom-radio-item-major",
	},
	{
		value: 3,
		label: "Critical",
		icon: exclamationIcon,
		iconActive: exclamationIconRed,
		className: "custom-radio-item-critical",
	},
]

const initialValues: Partial<ICaseForOpen> = {
	subject: "",
	text: "",
	serial_number: "",
	severity: Severity.NORMAL,
	author_name: "",
	author_email: "",
	author_phone_number: "",
	contractorId: null,
}

interface IProps {
	afterEvent: () => void;
	serial_number?: string;
	id?: number;
}

const CaseForm: FC<IProps> = ({ afterEvent, serial_number = "", id }) => {
	const [ form ] = Form.useForm();

	const subject = Form.useWatch("subject", form);
	const serialNumber = Form.useWatch("serial_number", form);
	const authorName = Form.useWatch("author_name", form);
	const authorEmail = Form.useWatch("author_email", form);
	const authorPhoneNumber = Form.useWatch("author_phone_number", form);
	const customerValue = Form.useWatch("customerValue", form);
	const subcontractorValue = Form.useWatch("subcontractor", form);

	const location = useLocation();
	const dispatch = useAppDispatch();

	const {
		isAuthorized,
		isMobile,
		organization,
		user,
	} = useAppSelector(getUserSelector);

	const [ stateBool, forceUpdate ] = useState(false);
	const [ files, setFiles ] = useState<File[]>([]);
	const [ caseEquipment, setCaseEquipment ] = useState<ICaseEquipment | null>(null);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isCustomerEdit, setCustomerIsEdit ] = useState(true);
	const [ isSubcontractorEdit, setSubcontractorIsEdit ] = useState(true);
	const [ clients, setClients ] = useState<any[]>([]);
	const [ clientsCopy, setClientsCopy ] = useState<any[]>([]);
	const [ author, setAuthor ] = useState<any>(null);
	const [ contractor, setContractor ] = useState<any>(null);
	const [ subcontractor, setSubcontractor ] = useState<any>(null);
	const [ serialNumberError, setSerialNumberError ] = useState("");
	const [ fileAttachments, setFileAttachments ] = useState<number[]>([]);
	const [ filesError, setFilesError ] = useState<boolean>(false);

	const { contractors, getContractors } = useContractors(100);

	const isSubmitDisabled = !subject || !customerValue || !subcontractorValue || !authorName || !authorEmail;

	// const { getRootProps, getInputProps, isDragActive } = useDropzone({
	// 	onDrop: useCallback((acceptedFiles: File[]) => {
	// 		let result: File[] = [];
	// 		acceptedFiles && Object.keys(acceptedFiles).forEach((label) => {
	// 			result.push(acceptedFiles![ +label ])
	// 		})
	// 		setFiles([ ...files, ...result ])
	// 	}, []),
	// 	multiple: true,
	// })

	const getClients = async (search: string) => {
		try {
			const response = await api.relevantUsers(
				// @ts-ignore
				{ search },
				{ page: 1, rowsPerPage: 20, count: 0 },
			);

			setClients([ ...response.data.results.map((res) => ({
				id: res.id,
				value: `${ res.first_name } ${ res.last_name }`,
				label: res.first_name,
				lastName: res.last_name,
				email: res.email,
				phone_number: res.phone_number,
			})) ]);
		} catch (e: any) {
			message.error(e.response.data.error || e.error || "Something went wrong!");
		}
	}

	const getClientsCopy = async (search: string) => {
		try {
			const response = await api.relevantUsers(
				// @ts-ignore
				{ search },
				{ page: 1, rowsPerPage: 20, count: 0 },
			);

			setClientsCopy([ ...response.data.results.map((res) => ({
				value: res.first_name,
				label: res.first_name,
				lastName: res.last_name,
				email: res.email,
				phone_number: res.phone_number,
			})) ]);
		} catch (e: any) {
			message.error(e.response.data.error || e.error || "Something went wrong!");
		}
	};

	const handleCompleteForm = async (values: ICaseForOpen) => {
		try {
			setIsLoading(true);

			await api.openCase({
					...values,
					attachments: fileAttachments,
					serial_number: !caseEquipment ? values.serial_number : undefined,
				},
				contractor ? contractor.id || contractor.value : undefined,
				subcontractor ? subcontractor.id || subcontractor.value : undefined,
				caseEquipment ? caseEquipment.id : undefined,
			);

			setIsLoading(false);
			message.success("Case was created.")
			afterCompleteEvent();
		} catch (e: any) {
			setIsLoading(false);
			message.error(e.response.data.error || e.error || "Something went wrong!");
		}
	}

	const afterCompleteEvent = () => {
		form.setFieldValue("subject", "");
		form.setFieldValue("serial_number", "");
		form.setFieldValue("text", "");
		form.setFieldValue("subcontractor", null);
		form.setFieldValue("email_cc", null);
		form.setFieldValue("severity", Severity.NORMAL);

		setSerialNumberError("");
		setFiles([]);

		location.pathname === RouteLinks.PROTECTED.CASES.MAIN && dispatch(getCases());
		afterEvent();
	}

	const findEquipment = async (serial_number: string, id?: number) => {
		if (serial_number !== "" && isAuthorized && user) {
			try {
				const { data: equipment } = await api.getEquipmentForCase(serial_number, id);

				setCaseEquipment(equipment);

				if (equipment) {
					setSubcontractorIsEdit(false);

					if (equipment.customer.is_my_org) {
						setCustomerIsEdit(false);
					}

					form.setFieldValue("customerValue", equipment.customer.name);
					form.setFieldValue("subcontractor", equipment.subcontractor.name);

					setContractor(equipment.customer);
					setSubcontractor(equipment.subcontractor);

					const data = equipment.customer.is_my_org ? {
						name: `${ user.first_name } ${ user.last_name }`,
						email: user.email,
						phone_number: user.phone_number,
					} : {
						name: "",
						email: "",
						phone_number: "",
					};

					form.setFieldValue("author_name", data.name);
					form.setFieldValue("author_email", data.email);
					form.setFieldValue(
						"author_phone_number",
						data.phone_number ? formatPhoneNumber(data.phone_number) : "",
					);
				}

				serialNumberError && setSerialNumberError("");
			} catch (e: any) {
				setCaseEquipment(null);
				setSerialNumberError(e.response.data.detail);

				// setContractor(null);
				// setSubcontractor(null);
				// setSubcontractorIsEdit(true);
				// form.setFieldValue("subcontractor", null);
			}
		} else {
			setCaseEquipment(null);
			setSerialNumberError("");
		}
	}

	const onCustomerSave = () => {
		setCustomerIsEdit(false);
	};

	const onSubcontractorSave = () => {
		setSubcontractorIsEdit(false);
	};

	const onCustomerChange = async (value: any) => {
		const { data: customer } = await api.getContractorRelatedMyOrg(value);

		setSubcontractor(customer);
		form.setFieldValue("subcontractor", customer.name);

		const contractorData = contractors.find((c) => c.value === value);

		setContractor(contractorData);

		const data = contractorData?.is_my_org && user ? {
			name: `${ user.first_name } ${ user.last_name }`,
			email: user.email,
			phone_number: user.phone_number,
		} : {
			name: "",
			email: "",
			phone_number: "",
		};

		setAuthor(data.name);
		form.setFieldValue("author_name", data.name);
		form.setFieldValue("author_email", data.email);
		form.setFieldValue(
			"author_phone_number",
			data.phone_number ? formatPhoneNumber(data.phone_number) : "",
		);

		setSubcontractorIsEdit(false);
		setSerialNumberError("");
	};

	const onSubcontractorChange = async (value: any) => {
		setSubcontractor(contractors.find(c => c.value === value));

		const { data: customer } = await api.getContractorRelatedMyOrg(value);

		const data = customer.is_my_org && user ? {
			name: `${ user.first_name } ${ user.last_name }`,
			email: user.email,
			phone_number: user.phone_number,
		} : {
			name: "",
			email: "",
			phone_number: "",
		};

		setAuthor(data.name);
		form.setFieldValue("author_name", data.name);
		form.setFieldValue("author_email", data.email);
		form.setFieldValue(
			"author_phone_number",
			data.phone_number ? formatPhoneNumber(data.phone_number) : "",
		);

		setContractor(customer);
		form.setFieldValue("customerValue", customer.name);

		setCustomerIsEdit(false);
	};

	const onSubcontractorEdit = () => {
		setSubcontractorIsEdit(true);
		// setSubcontractor(null);
	};

	const onUploadFiles = async () => {
		let attachments: number[] = [];

		try {
			await files.reduce(async (memo, file) => {
				await memo;
				const response = await api.uploadFile(file);

				attachments.push(response.id);
			}, Promise.resolve());

			setFilesError(false);
			setFileAttachments(attachments);
		} catch (error) {
			setFilesError(true);
			setFiles([]);
		}
	};

	useEffect(() => {
		if (files.length > 0) {
			onUploadFiles();
		}
	}, [ files ]);

	useEffect(() => {
		getContractors("");
		getClientsCopy("");
		// getClients("");

		serial_number !== "" && findEquipment(serial_number, id);
		form.setFieldValue("serial_number", serial_number);

		// if (isAuthorized && user) {
		// 	setAuthor(user.first_name + " " + user.last_name);
		//
		// 	form.setFieldValue("author_name", user.first_name + " " + user.last_name);
		// 	form.setFieldValue("author_email", user.email);
		// 	form.setFieldValue("author_phone_number", formatPhoneNumber(user.phone_number));
		// }
	}, []);

	return (
		<Form
			className={ `case-form-main ${ isMobile ? "mobile" : "" }` }
			name="case-form"
			layout="vertical"
			autoComplete="off"
			form={ form }
			onFinish={ handleCompleteForm }
			initialValues={ initialValues }
		>
			<h3>Complete the form</h3>

			<Form.Item
				label="Subject"
				name="subject"
				rules={ [ { required: true, message: "Please input subject!" } ] }
				style={ { marginBottom: 10 } }
			>
				<Input
					className="with-count"
					showCount
					maxLength={ 150 }
					placeholder="Add subject"
				/>
			</Form.Item>

			<Form.Item
				label="Serial Number"
				name="serial_number"
				style={ { marginBottom: 10 } }
			>
				<Input
					placeholder="Start with serial number or choose customer/subcontractor below"
					onChange={ (e) => findEquipment(e.target.value) }
				/>
			</Form.Item>

			{caseEquipment && (
				<div className="case-equipment-wrapper">
					<p className="case-equipment-description">{caseEquipment.product.description}</p>
					<p className="case-equipment-client">{caseEquipment.customer?.latin_name}</p>
					<div className="case-equipment-dates-wrapper">
						<p className="case-equipment-dates">{caseEquipment.date_begin} - {caseEquipment.date_end}</p>
						<Tag
							className={ `${ caseEquipment.is_pending ? "pending" : caseEquipment.support_type }` }
							style={ {
								background: caseEquipment.sla_ci.marker_bg_color,
								color: caseEquipment.sla_ci.marker_text_color,
							} }
						>
							{caseEquipment?.sla_ci.marker}
							{/*{ getSupportTypeTitle(caseEquipment.support_type, caseEquipment.is_active, caseEquipment.is_pending) }*/}
						</Tag>
					</div>
				</div>
			)}

			{(!caseEquipment && serialNumberError) && (
				<div className="notify-error">
					{serialNumberError}
				</div>
			)}

			<div className={ `case-form-group ${ isMobile ? "mobile" : "" }` }>
				<div className={ `user-info ${ isMobile ? "mobile" : "" }` }>
					<div className="case-form-group-title">
						<div>
							Customer
						</div>

						{isCustomerEdit ? (
							<Button
								size="small"
								onClick={ onCustomerSave }
							>
								Save
							</Button>
						) : (
							<Button
								type="text"
								size="small"
								icon={ <img src={ editIcon } alt="" style={ { margin: 0 } } /> }
								onClick={ () => setCustomerIsEdit(true) }
							/>
						)}
					</div>

					{!isCustomerEdit && (
						<>
							<div className="customer-card">
								<div className="customer-card-title">
									<img
										alt=""
										className="customer-card-title-icon"
										src={ contractor?.has_account ? hydraAccountBlue : hydraAccount }
									/>

									{contractor?.official_name || organization?.official_name}
								</div>

								<div className="customer-card-title">
									{authorName}
								</div>

								<div>
									<img
										alt=""
										src={ phoneIconGray }
										style={ { marginRight: 5, marginBottom: -1 } }
									/>

									<span>
										{authorPhoneNumber}
									</span>
								</div>

								<div>
									<img
										alt=""
										src={ emailIconGray }
										style={ { marginRight: 5, marginBottom: -1 } }
									/>

									<span>
										{authorEmail}
									</span>
								</div>
							</div>
						</>
					)}

					<div
						style={ isCustomerEdit
							? { visibility: "visible" }
							: { visibility: "hidden", position: "absolute", top: -1000 } }
					>
						<Form.Item
							label=""
							name="customerValue"
							style={ { marginBottom: 5 } }
						>
							<Select
								disabled={ serialNumber && !serialNumberError }
								options={ contractors }
								showSearch
								onSearch={ (value) => getContractors(value) }
								filterOption={ false }
								placeholder="Choose contractor"
								notFoundContent={ null }
								onChange={ onCustomerChange }
							/>
						</Form.Item>

						<div
							style={ { display: "flex", justifySelf: "center" } }
						>
							<Link
								to={ RouteLinks.PROTECTED.CONTRACTORS.NEW }
								target="_blank"
							>
								<Button
									type="link"
								>
									Create new one
								</Button>
							</Link>
						</div>

						<Form.Item
							label="Contact Name"
							name="author_name"
							valuePropName="label21"
							rules={ [ { required: true, message: "Please input name!" } ] }
							style={ { marginBottom: 15 } }
						>
							<AutoComplete
								allowClear
								autoClearSearchValue
								showSearch
								value={ author }
								onChange={ (value, option) => {
									setAuthor(value);

									if (value) {
										form.setFieldsValue({
											// @ts-ignore
											author_email: option?.email || "", author_phone_number: option?.phone_number || "",
										})
									}
								} }
								onSearch={ (value) => getClients(value) }
								filterOption={ false }
								placeholder="Name"
								options={ clients.map(el => ({
									id: el.id,
									value: `${ el.label } ${ el.lastName }`,
									phone_number: el.phone_number,
									email: el.email,
									label2: `${ el.label } ${ el.lastName }`,
									label: (
										<div>
											<div style={ { color: "#0C758C" } }>
												{el.label} {el.lastName}
											</div>
											<div
												style={ {
													fontSize: "12px",
													lineHeight: "14px",
													marginBottom: "2px",
													color: "#769197",
												} }
											>
												<img
													alt=""
													style={ { marginBottom: "-1px" } }
													src={ phoneIconGray }
												/> {el.phone_number}
											</div>

											<div
												style={ {
													fontSize: "12px",
													lineHeight: "14px",
													marginBottom: "2px",
													color: "#769197",
												} }
											>
												<img
													alt=""
													style={ { marginBottom: "-1px" } }
													src={ emailIconGray }
												/> {el.email}
											</div>
										</div>
									),
								})) }
								notFoundContent={ null }
								dropdownStyle={ { visibility: "visible" } }
							/>
						</Form.Item>

						<Form.Item
							className="mg-btn-0"
							label="Email"
							name="author_email"
							rules={ [ { required: true, message: "Please input your email!" }, {
								type: "email",
								message: "Please input correct email!",
							} ] }
							style={ { marginBottom: 15 } }
						>
							<Input
								placeholder="yourname@mail.com"
								prefix={ <img src={ emailIcon } alt="" /> }
							/>
						</Form.Item>

						<Form.Item
							className="mg-btn-0"
							label="Phone"
							name="author_phone_number"
							style={ { marginBottom: 0 } }
						>
							<Input
								placeholder="+375 (00) 000-00-00"
								prefix={ <img src={ phoneIcon } alt="" style={ { marginRight: "5px" } } /> }
								onChange={ (e) => form.setFieldsValue({ author_phone_number: formatPhoneNumber(e.target.value) }) }
							/>
						</Form.Item>
					</div>
				</div>

				<div className={ `user-info ${ isMobile ? "mobile" : "" }` }>
					<div className="case-form-group-title">
						<div>
							Subcontractor
						</div>

						{isSubcontractorEdit ? (
							<Button
								size="small"
								onClick={ onSubcontractorSave }
							>
								Save
							</Button>
						) : (
							<Button
								type="text"
								size="small"
								icon={ <img src={ editIcon } alt="" style={ { margin: 0 } } /> }
								onClick={ onSubcontractorEdit }
							/>
						)}
					</div>

					{!isSubcontractorEdit && subcontractor && (
						<>
							<div className="customer-card">
								<div className="customer-card-title">
									<img
										alt=""
										className="customer-card-title-icon"
										src={ subcontractor?.has_account ? hydraAccountBlue : hydraAccount }
									/>

									{subcontractor?.official_name}
								</div>

								<div>
									<img
										alt=""
										src={ phoneIconGray }
										style={ { marginRight: 5, marginBottom: -1 } }
									/>

									<span>
										{subcontractor?.phone_number}
									</span>
								</div>

								<div>
									<img
										alt=""
										src={ emailIconGray }
										style={ { marginRight: 5, marginBottom: -1 } }
									/>

									<span>
										{subcontractor?.default_email || subcontractor?.email}
									</span>
								</div>
							</div>
						</>
					)}

					<div
						style={ isSubcontractorEdit
							? { visibility: "visible" }
							: { visibility: "hidden", position: "absolute", top: -1000 } }
					>
						<Form.Item
							label=""
							name="subcontractor"
							style={ { marginBottom: 5 } }
						>
							<Select
								disabled={ serialNumber && !serialNumberError }
								options={ contractors }
								showSearch
								onSearch={ (value) => getContractors(value) }
								filterOption={ false }
								placeholder="Choose contractor"
								notFoundContent={ null }
								onChange={ onSubcontractorChange }
							/>
						</Form.Item>

						<div
							style={ { display: "flex", justifySelf: "center" } }
						>
							<Link
								to={ RouteLinks.PROTECTED.CONTRACTORS.NEW }
								target="_blank"
							>
								<Button
									type="link"
								>
									Create new one
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="cc-info">
				<div className="case-form-group-title">
					<div>
						<img
							alt=""
							src={ emailIcon }
							style={ { marginRight: 5 } }
						/>
						Cc
					</div>
				</div>

				<Form.Item
					label=""
					name="email_cc"
					style={ { flex: 1 } }
				>
					<Select
						mode="tags"
						options={ isAuthorized ? clientsCopy.map(el => ({
							value: el.email,
							label: (
								<div>
									<div style={ { color: "#0C758C" } }>
										{el.label} {el.lastName}
									</div>
									<div
										style={ {
											fontSize: "12px",
											lineHeight: "14px",
											marginBottom: "2px",
											color: "#769197",
										} }
									>
										<img
											alt=""
											style={ { marginBottom: "-1px" } }
											src={ emailIconGray }
										/> {el.email}
									</div>
								</div>
							),
							label2: (
								<div className="cc-list-label">
									<div className="cc-list-label-name">
										{el.label} {el.lastName}
									</div>

									<div className="cc-list-label-email">
										{el.email}
									</div>
								</div>
							),
						})) : undefined }
						optionLabelProp="label2"
						onSearch={ isAuthorized ? (value) => getClientsCopy(value) : undefined }
						filterOption={ false }
						placeholder="Name or email"
						notFoundContent={ null }
						dropdownStyle={ { visibility: isAuthorized ? "visible" : "hidden" } }
					/>
				</Form.Item>
			</div>

			<Form.Item
				label={
					<>
						Problem Description
						<label className="add-file-label">
							<img
								src={ addFileIcon }
								alt="" style={ { marginRight: "5px" } }
							/>
							Add file (up to 10Mb)
							<input
								type="file"
								style={ { display: "none" } }
								multiple
								onChange={ (e) => {
									let result: File[] = [];
									e.target.files && Object.keys(e.target.files).forEach((label) => {
										result.push(e.target.files![ +label ])
									})
									setFiles([ ...files, ...result ])
								} }
							/>
						</label>
					</>
				}
				name="text"
				className="form-with-button"
			>
				<Input.TextArea
					style={ { resize: "none", height: 116 } }
					placeholder="Add problem description"
				/>
				{/*<div*/}
				{/*	{ ...getRootProps() }*/}
				{/*	className={ isDragActive ? "file-dropzone" : "" }*/}
				{/*	onClick={ () => {*/}
				{/*	} }*/}
				{/*>*/}
				{/*	/!*<input { ...getInputProps() } />*!/*/}
				{/*	{isDragActive*/}
				{/*		? <p>Drop the files here...</p>*/}
				{/*		: <Input.TextArea*/}
				{/*			style={ { resize: "none", height: "58px" } }*/}
				{/*		/>*/}
				{/*	}*/}
				{/*</div>*/}
			</Form.Item>

			<div className="files-list">
				{files.map((file) => (
					<div key={ file.name } className="files-item">
						<img src={ attachedFileIcon } alt="" />
						{file.name}
						<div
							className="cross-file"
							onClick={ () => setFiles(files.filter((fl) => fl.name !== file.name)) }
						>
							<img src={ closeIcon } alt="" />
						</div>
					</div>
				))}
			</div>

			{filesError && (
				<div className="notify-error">
					Error loading files. Please try again.
				</div>
			)}

			<Form.Item
				name="severity"
				initialValue="info"
			>
				<div className={ `custom-radio-group ${ isMobile ? "mobile" : "" }` }>
					{severities.map((severity) => (
						<div
							className={ `custom-radio-item ${ (
								form.getFieldValue("severity") === undefined
									? severity.value === 0
									: form.getFieldValue("severity") === severity.value)
								? severity.className : ""
							}` }
							key={ severity.value }
							onClick={ () => {
								form.setFieldsValue({ severity: severity.value })
								forceUpdate(!stateBool);
							} }
						>
							<img
								alt=""
								src={ form.getFieldValue("severity") === severity.value ? severity.iconActive : severity.icon }
							/>
							<p className="custom-radio-title">{severity.label}</p>
						</div>
					))}
				</div>
			</Form.Item>

			{/*{additionalEmails.length > 0 && (*/}
			{/*	<Form.Item style={ { marginBottom: 0 } }>*/}
			{/*		{additionalEmails.map((item: string, id: number) => (*/}
			{/*			<Form.Item*/}
			{/*				key={ id }*/}
			{/*				className="mg-btn-0"*/}
			{/*				label={ `Email ${ id + 2 }` }*/}
			{/*				name={ `author_email-${ id + 2 }` }*/}
			{/*				rules={ [ { type: "email", message: "Please input correct email!" } ] }*/}
			{/*				style={ {*/}
			{/*					display: "inline-block",*/}
			{/*					width: "calc(50% - 10px)",*/}
			{/*					marginRight: id % 2 === 0 ? "20px" : "0",*/}
			{/*					marginTop: "15px",*/}
			{/*				} }*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					placeholder="yourname@mail.com"*/}
			{/*					prefix={ <img src={ emailIcon } alt="" /> }*/}
			{/*					value={ item }*/}
			{/*					onChange={ (e) => {*/}
			{/*						setAdditionalEmails(additionalEmails.map((mail, index) => index === id ? e.target.value : mail))*/}
			{/*					} }*/}
			{/*				/>*/}
			{/*			</Form.Item>*/}
			{/*		))}*/}
			{/*	</Form.Item>*/}
			{/*)}*/}

			<Form.Item
				className="mg-btn-0"
				style={ { marginTop: "15px" } }
			>
				<Button
					block
					className="big-btn"
					type="primary"
					htmlType="submit"
					disabled={ isSubmitDisabled }
				>
					<Loading
						isLoading={ isLoading }
						height={ 25 }
						width={ 81 }
					>
						Open Case
					</Loading>
				</Button>
			</Form.Item>
		</Form>
	)
}

export default CaseForm;
