import { FC, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Form, message } from "antd";
import dayjs from "dayjs";

import {
  ContractEquipmentsFilter,
  ContractEquipmentsTable,
  ContractInfoForm,
  ContractEquipmentsPagination,
} from "components/contracts";
import { useAppDispatch, useAppSelector } from "store";
import { getContract, getContractEquipments } from "store/contracts/contracts.thunks";
import { IEquipmentSource } from "store/listEquipment/listEquipment.types";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { IContractEquipment } from "store/contracts/contracts.types";
import { LayoutContext } from "context/LayoutContext";
import { RouteLinks } from "services/router.service";
import api from "services/api.service";

import "./CurrentContractPage.scss";

const CurrentContractPage: FC = () => {
  const { contractId } = useParams();
  const dispatch = useAppDispatch();

  const { isCurrentContractLoading, currentContract } = useAppSelector(getContractsSelector);
  const { setBreadcrumbs } = useContext(LayoutContext);

  const [ contractEdit, setContractEdit ] = useState<boolean>(false);
  const [ equipmentsForm ] = Form.useForm();

  const onSubmitEquipments = async (currentContractId: number): Promise<IEquipmentSource[]> => {
    const formEquipments = equipmentsForm.getFieldsValue(true);

    let contractEquipments = [];
    try {
      const data = [
        ...formEquipments.equipment.map((contractEquipment: IContractEquipment) => contractEquipment.serial_number !== "add_more" && ({
          id: contractEquipment.id,
          serial_number: contractEquipment.serial_number,
          product: contractEquipment.product?.id || contractEquipment.product,
          date_begin: dayjs(contractEquipment.date_begin).format("YYYY-MM-DD"),
          date_end: dayjs(contractEquipment.date_end).format("YYYY-MM-DD"),
          sla_ci: contractEquipment.sla_ci?.value || contractEquipment.sla_ci,
          quantity: 1,
        })),
      ].filter(Boolean);

      contractEquipments = await api.setContractEquipments(
        currentContractId,
        data,
      );
    } catch(e) {
      message.error("Something goes wrong.");
    }

    return contractEquipments;
  };

  useEffect(() => {
    if (contractId) {
      dispatch(getContract(+contractId));
      dispatch(getContractEquipments(+contractId));
    }
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      { url: RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN, label: "Contracts" },
      { label: `${ currentContract?.identifier || "Loading..." }` },
    ]);

    return () => {
      setBreadcrumbs([]);
    }
  }, [ currentContract ]);

  return (
    <div className="default-page-wrapper current-contract-page-wrapper">
      <ContractInfoForm
        contractEdit={ contractEdit }
        setContractEdit={ setContractEdit }
        onSubmitEquipments={ onSubmitEquipments }
      />
      { !isCurrentContractLoading && <ContractEquipmentsFilter /> }
      <ContractEquipmentsTable
        contractEdit={ contractEdit }
        equipmentsForm={ equipmentsForm }
      />
      <ContractEquipmentsPagination />
    </div>
  )
}

export default CurrentContractPage;
