import { FC } from "react";

import { useAppSelector } from "store";
import { getCasesSelector } from "store/cases/cases.selector";
import { Loading } from "components/common";
import { CaseItemMobile } from "components/cases";

import "./CasesMobileTable.scss";

const CasesMobileTable: FC = () => {
  const { cases, isCasesLoading } = useAppSelector(getCasesSelector);

  return (
    <Loading isLoading={ isCasesLoading } isColored height={ 200 }>
      <div className="cases-container">
        {cases.map(item => (
          <CaseItemMobile
            item={ item }
            key={ item.id }
          />
        ))}
      </div>
    </Loading>
  );
};

export default CasesMobileTable;
