import React from "react";

import "./Snackbar.scss";

interface ISnackbarProps {
	children: React.ReactNode;
	type: "info";
}

const Snackbar = ({ children, type }: ISnackbarProps) => {
	return (
		<div className={ `snackbar snackbar-${ type }` }>
			{children}
		</div>
	);
};

export default Snackbar;
