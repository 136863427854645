import { Button, message, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import api from "services/api.service";
import { Loading } from "components/common";
import { useAppDispatch, useAppSelector } from "store";
import { getInvitations } from "store/invitations/invitations.thunks";
import { getInvitationsSelector } from "store/invitations/invitations.selector";
import { formatDateForApi } from "services/data.service";

import "./ContractInvitationsTable.scss";

const ContractInvitationsTable = () => {
  const dispatch = useAppDispatch();

  const {
    isInvitationsLoading,
    invitations,
    filter,
  } = useAppSelector(getInvitationsSelector);

  const handleAccept = async (token: string) => {
    try {
     await api.contractInvitationAccept(token);

     dispatch(getInvitations());
    } catch (e) {
      message.error("error");
    }
  };

  const handleReject = async (token: string) => {
    try {
     await api.contractInvitationReject(token);

     dispatch(getInvitations());
    } catch (e) {
      message.error("error");
    }
  };

  const handleResend = async (token: string) => {
    try {
      await api.contractInvitationRefresh(token);

      dispatch(getInvitations());
    } catch (e) {
      message.error("error");
    }
  };

  const columnsInbox: ColumnsType = [
    {
      title: "Contract",
      dataIndex: "contract_identifier",
      key: "contract_identifier",
      width: 150,
    },
    {
      title: "Organization",
      dataIndex: "send_by_org",
      key: "send_by_org",
    },
    {
      title: "Expire at",
      dataIndex: "expire_at",
      key: "expire_at",
      width: 150,
      render: (value) => formatDateForApi(value),
    },
    {
      title: "Action/Status",
      dataIndex: "status",
      key: "status",
      width: 240,
      render: (value, item) => value === "In progress" ? (
        <>
          <Button
            size="small"
            type="primary"
            onClick={ () => handleAccept(item.token) }
            style={ { width: 100, marginRight: 10 } }
          >
            Accept
          </Button>

          <Button
            size="small"
            type="default"
            onClick={ () => handleReject(item.token) }
            style={ { width: 100 } }
          >
            Reject
          </Button>
        </>
      ) : value || "-//-",
    },
  ];

  const columnsOutbox: ColumnsType = [
    {
      title: "Contract",
      dataIndex: "contract_identifier",
      key: "contract_identifier",
      width: 150,
    },
    {
      title: "Organization",
      dataIndex: "send_to_contractor",
      key: "send_to_contractor",
    },
    {
      title: "Expire at",
      dataIndex: "expire_at",
      key: "expire_at",
      width: 150,
      render: (value) => formatDateForApi(value),
    },
    {
      title: "Action/Status",
      dataIndex: "status",
      key: "status",
      width: 240,
      render: (value, item) => value === "Expired" ? (
        <>
        <Button
          size="small"
          type="primary"
          onClick={ () => handleResend(item.token) }
        >
          Resend
        </Button> ({value})
        </>
      ) : value || "-//-",
    },
  ];

  return (
    <Loading
      isLoading={ isInvitationsLoading }
      isColored
      isFullWidth
      height={ 200 }
    >
      <Table
        className="invitations-table"
        columns={ filter.type === "inbox" ? columnsInbox : columnsOutbox }
        dataSource={ invitations }
        pagination={ false }
        rowKey={ (item) => item.token }
        scroll={ { y: window.innerHeight - 220 - (document.getElementById("contracts-header-cell")?.offsetHeight || 0) } }
        tableLayout="auto"
      />
    </Loading>
  );
};

export default ContractInvitationsTable;
