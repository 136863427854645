import { useState } from "react";

import {
  GeneralSettings,
  SettingsHeader,
} from "components/settings";
import { useBreadcrumbs } from "context/useBreadcrumbs";
import { RouteLinks } from "services/router.service";

import "./SettingsPage.scss";

const SettingsPage = () => {
  const [ activeTab, setActiveTab ] = useState("general");

  useBreadcrumbs([
    { url: RouteLinks.PROTECTED.SETTINGS.GENERAL, label: "Settings" },
    { label: "General settings" },
  ]);

  return (
    <div className="default-page-wrapper">
      <SettingsHeader
        activeTab={ activeTab }
        setActiveTab={ setActiveTab }
      />

      {activeTab === "general" && <GeneralSettings />}
    </div>
  );
};

export default SettingsPage;
