import { useContext, useEffect } from "react";

import { IBreadcrumb, LayoutContext } from "./LayoutContext";

export const useBreadcrumbs = (breadcrumbs: IBreadcrumb[]) => {
	const { setBreadcrumbs } = useContext(LayoutContext);

	useEffect(() => {
		setBreadcrumbs(breadcrumbs);

		return () => {
			setBreadcrumbs([]);
		}
	}, []);
};
