import { FC, useEffect, useState } from "react";

import { Button, message, Modal, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { IClient } from "store/userManagement/userManagement.types";
import { useAppDispatch, useAppSelector } from "store";
import { getUserManagementSelector } from "store/userManagement/userManagement.selector";
import { getClients } from "store/userManagement/userManagement.thunks";
import deleteIcon from "data/icons/trash.svg";
import editIcon from "data/icons/edit.svg";
import { Loading } from "components/common";
import api from "services/api.service";
import "components/cases/CasesTable/CasesTable.scss";

import "./TeamTable.scss";

const TeamTable: FC = () => {
  const dispatch = useAppDispatch();
  const { clients, isClientsLoading } = useAppSelector(getUserManagementSelector);

  const [ editingUserIdRole, setEditingUserIdRole ] = useState<number>(0);
  const [ roles, setRoles ] = useState([ { value: "", label: "None" } ]);

  const [ editingUser, setEditingUser ] = useState<any>(null);
  const [ deletingUser, setDeletingUser ] = useState<any>(null);

  const getUserRoles = async () => {
    try {
      const { data } = await api.usersRoles();

      setRoles(data.map((role: { display: string; value: string; }) => ({
        value: role.value,
        label: role.display,
      })));
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  };

  const onChangeRole = (user: any, role: string) => {
    setEditingUser({ ...user, nextRole: role });
  };

  const onDeleteUser = (user: IClient) => {
    setDeletingUser(user);
  };

  const handleDeleteUser = async () => {
    try {
      const { data } = await api.usersDelete(deletingUser.id);

      message.success(data.detail);
      setDeletingUser(null);
      dispatch(getClients());
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  };

  const clearUser = () => {
    setEditingUser(null);
    dispatch(getClients());
    setEditingUserIdRole(0);
  };

  const handleUserRole = async () => {
    try {
      await api.userRole(editingUser.id, editingUser.nextRole);
    } catch (e: any) {
      message.error(e.response.data.detail || e.error || "Something went wrong!");
    }

    clearUser();
  };

  useEffect(() => {
    dispatch(getClients());
    getUserRoles();
  }, []);

  const columns: ColumnsType<IClient> = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (value) => <p className="active thin">{ value }</p>,
      width: "9%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: "9%",
      render: (value) => <p className="active thin">{ value }</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "16%",
      render: (value) => <p className="active thin">{ value }</p>,
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "14%",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "11%",
      render: (value, item) => editingUserIdRole !== Number(item.id) ? (
        <div
          className="profile-edit-link"
          onClick={ () => setEditingUserIdRole(Number(item.id)) }
        >
          {roles.find(role => role.value === value)?.label || ""}
          <img src={ editIcon } alt="" />
        </div>
      ) : (
        <Select
          size="small"
          defaultValue={ value }
          placeholder="Select role"
          onChange={ (value) => onChangeRole(item, value) }
          options={ value ? [ { value: null, label: "None" }, ...roles ] : roles }
          style={ { width: "120px" } }
        />
      ),
      showSorterTooltip: false,
    },
    {
      title: "",
      dataIndex: "action_delete",
      key: "action_delete",
      width: "2%",
      render: (_value, item) => (
        <Button
          type="text"
          size="small"
          className="team-table-delete-button"
          icon={ <img src={ deleteIcon } alt=""/> }
          onClick={ () => onDeleteUser(item) }
        >
        </Button>
      ),
    },
  ];

  return (
    <Loading
      isLoading={ isClientsLoading }
      isColored
      isFullWidth
      height={ 200 }
    >
      <Table
        className="cases-table user-management-table"
        columns={ columns }
        dataSource={ clients }
        pagination={ false }
        rowKey={ item => item.id }
        scroll={ { y: window.innerHeight - 183 - (document.getElementById("user-management-header-cell")?.offsetHeight || 0) } }
        tableLayout="auto"
      />

      <Modal
        className="user-accept-modal-wrapper"
        open={ !!editingUser }
        onCancel={ clearUser }
        footer={ null }
        width={ 400 }
      >
        <>
          <h4 className="modal-title">
            Are you sure you want to change the role to
            {" "}
            <span>{roles.find(role => role.value === editingUser?.nextRole)?.label || "None" }</span>
            {" "}for{" "}
            <span>{ editingUser?.first_name } { editingUser?.last_name }</span>?
          </h4>

          <div className="action-wrapper">
            <Button
              type="primary"
              onClick={ handleUserRole }
            >
              Yes
            </Button>

            <Button onClick={ clearUser }>
              No
            </Button>
          </div>
        </>
      </Modal>

      <Modal
        className="user-accept-modal-wrapper"
        open={ !!deletingUser }
        onCancel={ () => setDeletingUser(null) }
        footer={ null }
        width={ 500 }
      >
        <>
          <h4 className="modal-title">
            Are you sure you want to block
            {" "}
            <span>{ deletingUser?.first_name } { deletingUser?.last_name }</span>?
          </h4>

          <div>
            You can find blocked users in the Archive section
          </div>

          <div className="action-wrapper">
            <Button
              type="primary"
              onClick={ handleDeleteUser }
            >
              Yes
            </Button>

            <Button onClick={ () => setDeletingUser(null) }>
              No
            </Button>
          </div>
        </>
      </Modal>
    </Loading>
  );
};

export default TeamTable;
