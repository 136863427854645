import { FC, useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getCurrentCase } from "store/cases/cases.thunks";
import backIcon from "data/icons/arrow-left.svg";
import { CaseComments, ContactInfoCard, CurrentCaseCard } from "components/cases";
import { getUserSelector } from "store/user/user.selector";
import { ICaseActionsLog } from "store/cases/cases.types";
import { LayoutContext } from "context/LayoutContext";
import { getCasesSelector } from "store/cases/cases.selector";
import { RouteLinks } from "services/router.service";
import api from "services/api.service";

import "./CurrentCasePage.scss";

const CurrentCasePage: FC = () => {
  const navigate = useNavigate();
  const { caseId } = useParams();
  const dispatch = useAppDispatch();

  const { isMobile } = useAppSelector(getUserSelector);
  const { currentCase } = useAppSelector(getCasesSelector);
  const { setBreadcrumbs } = useContext(LayoutContext);

  const [ dataDisplay, setDataDisplay ] = useState<"info" | "contacts">("info")
  const [ actionsLog, setActionsLog ] = useState<ICaseActionsLog>();

  const onLoadActionsLog = async () => {
    if (caseId) {
      const response = await api.getActionsLog(caseId);

      setActionsLog(response.data);
    }
  };

  useEffect(() => {
    caseId && dispatch(getCurrentCase(caseId, () => {
      navigate("/not-found");
    }));
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      { url: RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN, label: "Contracts" },
      { label: `${ currentCase?.custom_id || "Loading..." }` },
    ]);

    return () => {
      setBreadcrumbs([]);
    }
  }, [ currentCase ]);

  return (
    <div className="default-page-wrapper current-case-page-wrapper">
      <Button
        className="back-button"
        type="link"
        onClick={ () => navigate(RouteLinks.PROTECTED.CASES.MAIN) }
      >
        <img src={ backIcon } alt="" />
        Back
      </Button>

      <div className="cards-wrapper">
        <CurrentCaseCard
          dataDisplay={ dataDisplay }
          setDataDisplay={ setDataDisplay }
          onLoadActionsLog={ onLoadActionsLog }
        />

        { !isMobile && <ContactInfoCard /> }
      </div>

      <CaseComments
        actionsLog={ actionsLog }
        onLoadActionsLog={ onLoadActionsLog }
      />
    </div>
  )
}

export default CurrentCasePage;
