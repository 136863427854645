import { Tag } from "antd";
import { useNavigate } from "react-router-dom";

import { formatDate } from "services/title.service";
import { ICase } from "store/cases/cases.types";
import grayDotIcon from "data/icons/dot-gray.svg";
import { RouteLinks } from "services/router.service";
import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import hydraAccount from "data/icons/hydra-account.svg";

import "./CaseItemMobile.scss";

interface ICaseItemMobileProps {
	item: ICase;
}

const CaseItemMobile = ({ item }: ICaseItemMobileProps) => {
	const navigate = useNavigate();

	return (
		<div
			className="case-item"
			onClick={ () => navigate(RouteLinks.PROTECTED.CASES.CASE + "/" + item.id) }
		>
			<div className="case-item-title">
        <span className="case-item-title-text">
          {item.custom_id}
        </span>

				<img
					className="case-item-title-icon"
					src={ grayDotIcon }
					alt=""
				/>

				<span className="case-item-title-date">
					{formatDate(item.created_at)}
				</span>

				{item.sla_ci && (
					<div className="case-item-title-tooltip">
						<Tag
							style={ {
								background: item.sla_ci.marker_bg_color,
								color: item.sla_ci.marker_text_color,
								margin: 0,
							} }
						>
							{item.sla_ci.marker}
						</Tag>
					</div>
				)}
			</div>

			<div className="case-item-content">
				{item.subject}
			</div>

			<div className="case-item-info">
				<img
					alt=""
					className="case-item-info-icon"
					src={ item.contractor.has_account ? hydraAccountBlue : hydraAccount }
				/>

				<span>
					{item.contractor.official_name}
				</span>
			</div>
		</div>
	);
};

export default CaseItemMobile;
