import { useEffect, useState } from "react";

import { Button, Form, Input, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { RouteLinks } from "services/router.service";
import backIcon from "data/icons/arrow-left.svg";
import editBlue from "data/icons/edit-blue.svg";
import phoneIcon from "data/icons/phone.svg";
import { getContractor } from "store/contractors/contractors.thunks";
import { getContractorsSelector } from "store/contractors/contractors.selector";
import { formatPhoneNumber } from "services/data.service";
import { useBreadcrumbs } from "context/useBreadcrumbs";
import api from "services/api.service";

import "./ContractorForm.scss";

const initialValues = {
  default_email: "",
  name: "",
  official_name: "",
  phone_number: "",
};

const ContractorForm = () => {
  const { contractorId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [ form ] = Form.useForm();
  const dispatch = useAppDispatch();
  const { currentContractor } = useAppSelector(getContractorsSelector);

  const [ edit, setEdit ] = useState<boolean>(false);

  const handleCompleteForm = async (values: any) => {
    try {
      if (location.pathname === RouteLinks.PROTECTED.CONTRACTORS.NEW) {
        await api.addContractor(values);
      } else {
        await api.editContractor({
          id: Number(contractorId),
          ...values,
        });
      }

      navigate(RouteLinks.PROTECTED.CONTRACTORS.LIST);
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  useEffect(() => {
    if (!isNaN(Number(contractorId))) {
      dispatch(getContractor(Number(contractorId)));
    }
  }, []);

  useEffect(() => {
    if (contractorId && currentContractor) {
      form.setFieldsValue({
        ...currentContractor,
        phone_number: formatPhoneNumber(currentContractor.phone_number || ""),
      });
    }

    return () => {
      form.setFieldsValue(initialValues);
    }
  }, [ currentContractor ]);

  useBreadcrumbs([
    { url: RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN, label: "Contractors" },
    { label: "Add contractor" },
  ]);

  return (
    <div className="default-page-wrapper">
      <div className="contractor-form-wrapper">
        <Button
          className="back-button"
          type="link"
          onClick={ () => navigate(RouteLinks.PROTECTED.CONTRACTORS.LIST) }
        >
          <img src={ backIcon } alt="" />
          Back
        </Button>

        {(edit || pathname === RouteLinks.PROTECTED.CONTRACTORS.NEW) && (
          <Form
            className="contractor-form"
            layout="vertical"
            form={ form }
            onFinish={ handleCompleteForm }
          >
            <Form.Item
              label="Default email"
              name="default_email"
              rules={ [ { required: true, message: "Please input default email!" } ] }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={ [ { required: true, message: "Please input name!" } ] }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Official name"
              name="official_name"
              rules={ [ { required: true, message: "Please input official name!" } ] }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phone_number"
            >
              <Input
                placeholder="+375 (00) 000-00-00"
                prefix={ <img src={ phoneIcon } alt="" style={ { marginRight: "5px" } } /> }
                onChange={ (e) => form.setFieldsValue({ phone_number: formatPhoneNumber(e.target.value) }) }
              />
            </Form.Item>
            <div className="buttons-wrapper">
              <Button
                htmlType="submit"
                type="primary"
              >
                Save
              </Button>
              <Button onClick={ () => navigate(RouteLinks.PROTECTED.CONTRACTORS.LIST) }>
                Cancel
              </Button>
            </div>
          </Form>
        )}

        {(!edit && pathname !== RouteLinks.PROTECTED.CONTRACTORS.NEW) && (
          <div className="contractor-view-wrapper">
            <div className="contractor-info">
              <div className="contractor-info-item">
                <div className="contractor-info-title">
                  Default email :
                </div>
                <div className="contractor-info-value">
                  {currentContractor?.default_email}
                </div>
              </div>
              <div className="contractor-info-item">
                <div className="contractor-info-title">
                  Name :
                </div>
                <div className="contractor-info-value">
                  {currentContractor?.name}
                </div>
              </div>
              <div className="contractor-info-item">
                <div className="contractor-info-title">
                  Official name :
                </div>
                <div className="contractor-info-value">
                  {currentContractor?.official_name}
                </div>
              </div>
              <div className="contractor-info-item">
                <div className="contractor-info-title">
                  Phone number :
                </div>
                <div className="contractor-info-value">
                  {formatPhoneNumber(currentContractor?.phone_number || "")}
                </div>
              </div>
            </div>
            <Button className="edit-button" onClick={ () => setEdit(true) }>
              Edit
              <img src={ editBlue } alt="" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContractorForm;
