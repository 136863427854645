import { FC, useEffect } from "react";

import {
  ContractorsFooter,
  ContractorsHeader,
  ContractorsTable,
} from "components/contractors";
import {
  updateContractorsFilter,
  updateContractorsPagination,
} from "store/contractors/contractors.actions";
import { useAppDispatch } from "store";
import { getContractors } from "store/contractors/contractors.thunks";
import { initialState } from "store/contractors/contractors.reducer";
import { useBreadcrumbs } from "context/useBreadcrumbs";

const ContractorsPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getContractors());

    return () => {
      dispatch(updateContractorsPagination(initialState.pagination.page));
      dispatch(updateContractorsFilter(initialState.filter));
    }
  }, []);

  useBreadcrumbs([
    { label: "Contractors" },
  ]);

  return (
    <div className="default-page-wrapper">
      <ContractorsHeader />
      <ContractorsTable />
      <ContractorsFooter />
    </div>
  )
}

export default ContractorsPage;
