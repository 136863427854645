import { AxiosResponse } from "axios";

export const downloadFile = (response: AxiosResponse) => {
	const url = window.URL.createObjectURL(new Blob([ response.data ]));
	const link = document.createElement("a");

	link.href = url;
	link.download = response.headers?.[ "content-disposition" ] || "downloaded-file";
	document.body.appendChild(link);

	link.click();

	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
}
