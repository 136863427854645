import { useEffect, useState } from "react";

import api from "services/api.service";
import { IOrganization } from "store/user/user.types";

export const useCompanyData = () => {
	const [ company, setCompany ] = useState<IOrganization>();
	const [ error, setError ] = useState(false);

	const getCompanyData = async () => {
		try {
			const response = await api.getCompanyData();

			if (response.data) {
				setCompany(response.data);
			} else {
				setError(true);
			}
		} catch (err) {
			setError(true);
		}
	};

	useEffect(() => {
		getCompanyData();
	}, []);

	return {
		company,
		setCompany,
		error,
	};
};
