import { Tooltip } from "antd";

import active from "data/icons/check-green.svg";
import expired from "data/icons/expired.svg";
import pending from "data/icons/pending.svg";

interface IStatusItem {
	is_active: boolean;
	is_pending: boolean;
}

const StatusItem = ({ is_active, is_pending }: IStatusItem) => {
	return (
		<>
			{is_active && (
				<Tooltip title="Active">
					<img alt="" src={ active } />
				</Tooltip>
			)}

			{is_pending && (
				<Tooltip title="Pending">
					<img alt="" src={ pending } />
				</Tooltip>
			)}

			{!is_active && !is_pending && (
				<Tooltip title="Expired">
					<img alt="" src={ expired } />
				</Tooltip>
			)}
		</>
	);
};

export default StatusItem;
