import React, { useState, FC } from "react";

import { IBreadcrumb, LayoutContext } from "./LayoutContext";

interface IProps {
	children: React.ReactNode
}

export const LayoutProvider: FC<IProps> = ({ children }) => {
	const [ breadcrumbs, setBreadcrumbs ] = useState<IBreadcrumb[]>([]);

	return (
		<LayoutContext.Provider
			value={ {
				breadcrumbs,
				setBreadcrumbs,
			} }
		>
			{children}
		</LayoutContext.Provider>
	);
};
