import { useState } from "react";

import {
  Team,
  SettingsHeader,
} from "components/settings";
import { useBreadcrumbs } from "context/useBreadcrumbs";
import { RouteLinks } from "services/router.service";

import "./TeamSettings.scss";

const TeamSettings = () => {
  const [ activeTab, setActiveTab ] = useState("team");

  useBreadcrumbs([
    { url: RouteLinks.PROTECTED.SETTINGS.GENERAL, label: "Settings" },
    { label: "Team" },
  ]);

  return (
    <div className="default-page-wrapper">
      <SettingsHeader
        activeTab={ activeTab }
        setActiveTab={ setActiveTab }
      />
      {activeTab === "team" && <Team />}
    </div>
  );
};

export default TeamSettings;
